import {
  ActionIcon,
  ActionIconProps,
  Input,
  InputWrapperProps,
  Tooltip,
} from '@mantine/core';
import { IconEye, IconEyeExclamation } from '@tabler/icons-react';
import { ReactNode } from 'react';
import { match } from 'ts-pattern';

export interface InvalidVisibilityToggleProps {
  value: boolean;
  onToggle: () => void;
  label: ReactNode;
  labelVariant: 'top' | 'tooltip';
  hidden?: boolean;
  actionIconProps?: ActionIconProps;
  inputWrapperProps?: Omit<InputWrapperProps, 'label' | 'children'>;
}

export function InvalidVisibilityToggle(props: InvalidVisibilityToggleProps) {
  const {
    value,
    onToggle,
    labelVariant,
    hidden,
    label,
    actionIconProps,
    inputWrapperProps,
  } = props;
  const actionIcon = (
    <ActionIcon
      variant={value ? 'filled' : 'outline'}
      onClick={() => onToggle()}
      color={value ? 'orange' : undefined}
      {...actionIconProps}
    >
      {value ? <IconEyeExclamation size='1rem' /> : <IconEye size='1rem' />}
    </ActionIcon>
  );
  return match(labelVariant)
    .with('tooltip', () => <Tooltip label={label}>{actionIcon}</Tooltip>)
    .with('top', () => (
      <Input.Wrapper
        label={label}
        style={{ visibility: hidden ? 'hidden' : undefined }}
        {...inputWrapperProps}
      >
        {actionIcon}
      </Input.Wrapper>
    ))
    .exhaustive();
}
