import { Badge, Group } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction } from 'react';
import { ContainerIdName } from '../../Container/ContainerIdName';
import { openDeleteEntityConfirmModal } from '../../Form/useDeleteEntityModal.tsx';
import {
  BufferTransferIcon,
  ContainerIcon,
  EmptyContainerIcon,
  PartiallyFullContainerIcon,
} from '../../Icons';
import { ProcessIdName } from '../../Process/ProcessIdName';
import { useDeleteProcessBufferTransfer } from '../../api/processBufferTransfer.ts';
import { CalendarDateTime } from '../../common';
import { ProcessBufferTransferDTO } from '../../rest-client';
import { DeleteMenuItem } from '../DeleteMenuItem.tsx';
import { EditMenuItem } from '../EditMenuItem.tsx';
import { CurrentDrawerState } from '../InventoryLedgerPage.tsx';
import { InventoryLedgerRowTemplate } from '../InventoryLedgerRowTemplate';
import { useInventoryLedgerStatusContext } from '../LedgerStatusContext';
import { RowActionsMenu } from '../RowActionsMenu.tsx';

export const ProcessBufferTransferRow = ({
  bufferTransferEvent,
  setCurrentDrawer,
}: {
  bufferTransferEvent: ProcessBufferTransferDTO;
  setCurrentDrawer: Dispatch<SetStateAction<CurrentDrawerState>>;
}) => {
  const ledgerStatus = useInventoryLedgerStatusContext();
  const status = ledgerStatus.transactionStatus(bufferTransferEvent);
  const timestamp = bufferTransferEvent.effectiveTimestamp;

  const deleteMutation = useDeleteProcessBufferTransfer();
  const openDeleteModal = openDeleteEntityConfirmModal(
    'Feedstock Transfer',
    () => {
      deleteMutation.mutate(
        { processBufferTransferId: bufferTransferEvent.id },
        {
          onError() {
            showNotification({
              title: 'Error Deleting Feedstock Transfer',
              message: 'An error occurred deleting the feedstock transfer.',
              color: 'red',
              icon: <IconX />,
            });
          },
          onSuccess() {
            showNotification({
              title: 'Feedstock Transfer Deleted',
              message: 'The feedstock transfer has successfully been deleted.',
              color: 'green',
              icon: <IconCheck />,
            });
          },
          onSettled() {
            deleteMutation.reset();
          },
        },
      );
    },
  );

  const renderEventTypeCell = (
    <Group spacing='xs'>
      <BufferTransferIcon />
      <Badge color='yellow'>Feedstock Transfer</Badge>
    </Group>
  );

  const sourceCell = (
    <Group spacing='xs'>
      <ContainerIcon />
      <ContainerIdName
        containerId={bufferTransferEvent.containerId}
        time={dayjs.utc(bufferTransferEvent.effectiveTimestamp)}
      />
      {bufferTransferEvent.sourceEmptied ? (
        <EmptyContainerIcon />
      ) : (
        <PartiallyFullContainerIcon />
      )}
    </Group>
  );

  const destinationCell = (
    <ProcessIdName processId={bufferTransferEvent.processId} withIcon />
  );

  const actionsCell = (
    <RowActionsMenu>
      <EditMenuItem
        onClick={() =>
          setCurrentDrawer({
            drawerName: 'Edit Feedstock Transfer',
            id: bufferTransferEvent.id,
          })
        }
      />
      <DeleteMenuItem onClick={openDeleteModal} />
    </RowActionsMenu>
  );

  return (
    <InventoryLedgerRowTemplate
      eventType={renderEventTypeCell}
      date={<CalendarDateTime iso8601={timestamp} />}
      entryStatus={status}
      source={sourceCell}
      destination={destinationCell}
      actions={actionsCell}
    />
  );
};
