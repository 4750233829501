import { Alert, Group, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import dayjs, { Dayjs } from 'dayjs';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useFacilityContext } from '../Facility/FacilityContext';
import { SelectedFacilityRequiredDateTimePicker } from '../FacilityDateTimePicker';
import { FormActionButton } from '../Form/FormActionButton';
import { ProcessSelect } from '../Process/ProcessSelect';
import { useAddProcessBufferDepletion } from '../api/processBufferDepletion';
import { ProcessId } from '../rest-client';

export interface AddProcessBufferDepletionFormProps {
  onSuccess: () => void;
  onCancel: () => void;
}

export interface ProcessBufferDepletionFormValues {
  processId: ProcessId | null;
  depletedAt: Dayjs;
}

export function AddProcessBufferDepletionForm(
  props: AddProcessBufferDepletionFormProps,
) {
  const { onSuccess, onCancel } = props;

  const id = useMemo(() => uuidv4(), []);

  const facility = useFacilityContext();
  const form = useForm<ProcessBufferDepletionFormValues>({
    initialValues: {
      processId: null,
      depletedAt: dayjs.utc().tz(facility.timeZoneId),
    },
    validate: {
      processId: (value) => (value ? null : 'Process is required'),
    },
  });

  const addMutation = useAddProcessBufferDepletion();

  if (addMutation.isError) {
    return (
      <Alert
        color='red'
        title='Error Creating Feedstock Depletion'
        withCloseButton
        closeButtonLabel='Clear Error'
        onClose={() => addMutation.reset()}
      >
        The feedstock depletion may or may not have been saved. You can clear
        the error and try again.
      </Alert>
    );
  }

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        if (!values.processId) {
          throw new Error();
        }

        addMutation.mutate(
          {
            id,
            processId: values.processId,
            effectiveTimestamp: values.depletedAt.utc().toISOString(),
          },
          {
            onError() {
              showNotification({
                title: 'Error Creating Feedstock Depletion',
                message: `An error occurred creating the feedstock depletion.`,
                color: 'red',
                icon: <IconX />,
              });
            },
            onSuccess() {
              showNotification({
                title: 'Feedstock Depletion Created',
                message: `The feedstock depletion was successfully created.`,
                color: 'green',
                icon: <IconCheck />,
              });

              onSuccess();
            },
          },
        );
      })}
    >
      <Stack>
        <SelectedFacilityRequiredDateTimePicker
          required
          withAsterisk
          label='Time Feedstock Material was Removed'
          maxDate={dayjs()}
          {...form.getInputProps('depletedAt')}
        />

        <ProcessSelect
          required
          withAsterisk
          label='Process'
          emptyContent={
            <Alert title='No Processes' color='red'>
              Cannot create a feedstock depletion without a process.
            </Alert>
          }
          {...form.getInputProps('processId')}
        />

        <Group position='right'>
          <FormActionButton
            action='cancel'
            onClick={onCancel}
            loading={addMutation.isLoading}
          >
            Cancel
          </FormActionButton>
          <FormActionButton
            type='submit'
            action='saveCreation'
            loading={addMutation.isLoading}
          >
            Add Feedstock Depletion
          </FormActionButton>
        </Group>
      </Stack>
    </form>
  );
}
