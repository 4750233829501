import { TextInput } from '@mantine/core';
import { UseFormReturnType, useForm, zodResolver } from '@mantine/form';
import { ZodType, z } from 'zod';
import { MaterialClassMultiSelect } from '../MaterialClass/MaterialClassMultiSelect';

export type RecoveryGoalFormValues = {
  name: string;
  shortName: string | null;
  description: string | null;
  materialClassIds: string[];
};

const RecoveryGoalFormSchema: ZodType<RecoveryGoalFormValues> = z.object({
  name: z.string().min(1, { message: 'Name is required' }),
  shortName: z
    .string()
    .max(4, { message: 'Short name must be 1-4 characters' })
    .nullable(),
  description: z.string().nullable(),
  materialClassIds: z.string().uuid().array(),
});

export function useRecoveryGoalForm() {
  return useForm<RecoveryGoalFormValues>({
    initialValues: {
      name: '',
      shortName: null,
      description: null,
      materialClassIds: [],
    },
    validate: zodResolver(RecoveryGoalFormSchema),
  });
}

export function RecoveryGoalFormFields(props: {
  form: UseFormReturnType<RecoveryGoalFormValues>;
}) {
  const { form } = props;

  return (
    <>
      <TextInput
        label='Recovery Goal Name'
        placeholder='Name'
        autoFocus
        withAsterisk
        {...form.getInputProps('name')}
      />
      <TextInput
        label='Short Name'
        placeholder='Short name'
        description='Abbreviated name to use across the application.'
        {...form.getInputProps('shortName')}
      />
      <TextInput
        label='Description'
        placeholder='Description'
        description='Describe the intended behavior of the recovery goal.'
        {...form.getInputProps('description')}
      />
      <MaterialClassMultiSelect
        description='Which material classes does this recovery goal target?'
        {...form.getInputProps('materialClassIds')}
      />
    </>
  );
}
