import { TextInput } from '@mantine/core';
import { UseFormReturnType, useForm, zodResolver } from '@mantine/form';
import { ZodType, z } from 'zod';
import { FacilitySelect } from '../Facility/FacilitySelect';

export type InternalMaterialSinkFormValues = {
  name: string;
  facilityId: string;
};

const InternalMaterialSinkFormSchema: ZodType<InternalMaterialSinkFormValues> =
  z.object({
    name: z.string().min(1, { message: 'Name is required' }),
    facilityId: z.string().uuid({ message: 'Facility is required' }),
  });

export function useInternalMaterialSinkForm() {
  return useForm<InternalMaterialSinkFormValues>({
    initialValues: {
      name: '',
      facilityId: '',
    },
    validate: zodResolver(InternalMaterialSinkFormSchema),
  });
}

export function InternalMaterialSinkFormFields(props: {
  form: UseFormReturnType<InternalMaterialSinkFormValues>;
}) {
  const { form } = props;
  return (
    <>
      <TextInput
        label='Material Export Destination Name'
        placeholder='Name'
        autoFocus
        withAsterisk
        {...form.getInputProps('name')}
      />
      <FacilitySelect withAsterisk {...form.getInputProps('facilityId')} />
    </>
  );
}
