import { useDisclosure } from '@mantine/hooks';
import dayjs from 'dayjs';
import { ContainerIdName } from '../../Container/ContainerIdName';
import { EditContainerTransferDrawerForm } from '../../ContainerTransfer/EditContainerTransferDrawerForm';
import { useDeleteEntityModal } from '../../Form/useDeleteEntityModal';
import { useDeleteContainerTransfer } from '../../api/containerTransfer';
import { CalendarDateTime } from '../../common';
import { MaterialContainerTransferDTO } from '../../rest-client';
import { DeleteMenuItem } from '../DeleteMenuItem';
import { EditMenuItem } from '../EditMenuItem';
import { InventoryLedgerRowTemplate } from '../InventoryLedgerRowTemplate';
import { TransactionStatus } from '../LedgerStatusContext';
import { RowActionsMenu } from '../RowActionsMenu';
import { TransferTypeCell } from '../TransactionTypeCell';

export function ContainerTransferRow(props: {
  id: string;
  transfer: MaterialContainerTransferDTO;
  status: TransactionStatus;
}) {
  const { transfer, status, id } = props;
  const deleteMutation = useDeleteContainerTransfer();
  const openDeleteModal = useDeleteEntityModal(
    transfer.id,
    deleteMutation,
    'Container Material Transfer',
  );

  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  return (
    <>
      <InventoryLedgerRowTemplate
        id={id}
        entryStatus={status}
        date={<CalendarDateTime iso8601={transfer.effectiveTimestamp} />}
        eventType={<TransferTypeCell partial={!transfer.sourceEmptied} />}
        source={
          <ContainerIdName
            containerId={transfer.sourceContainerId}
            variant='icon-name-link'
            time={dayjs.utc(transfer.effectiveTimestamp)}
          />
        }
        destination={
          <ContainerIdName
            containerId={transfer.destinationContainerId}
            variant='icon-name-link'
            time={dayjs.utc(transfer.effectiveTimestamp)}
          />
        }
        actions={
          <RowActionsMenu>
            <EditMenuItem onClick={() => openDrawer} />
            <DeleteMenuItem onClick={openDeleteModal} />
          </RowActionsMenu>
        }
      />
      {/* TODO: get this to show up; drawer not opening on click, might need some context component */}
      <EditContainerTransferDrawerForm
        transferId={transfer.id}
        opened={drawerOpened}
        onClose={closeDrawer}
      />
    </>
  );
}
