import { useForm, zodResolver } from '@mantine/form';
import { ZodType, z } from 'zod';
import { MaterialClassSetDTO } from '../../rest-client';

export type ManualSampleAnalysisFormValues = {
  specifiedTotalScaleDisplayDivisions: number | null;
  materialClasses: {
    materialClassId: string;
    netWeightScaleDisplayDivisions: number | null;
  }[];
};

const ManualSampleAnalysisFormSchema: ZodType<ManualSampleAnalysisFormValues> =
  z.object({
    specifiedTotalScaleDisplayDivisions: z
      .number()
      .nonnegative({ message: 'Total sample weight cannot be negative' })
      .nullable(),
    materialClasses: z
      .object({
        materialClassId: z.string().uuid(),
        netWeightScaleDisplayDivisions: z
          .number()
          .nonnegative('Material class weight cannot be negative')
          .nullable(),
      })
      .array(),
  });

export function useManualSampleAnalysisForm(
  materialClassSet: MaterialClassSetDTO,
  initialValues?: ManualSampleAnalysisFormValues,
) {
  return useForm<ManualSampleAnalysisFormValues>({
    initialValues: initialValues ?? {
      specifiedTotalScaleDisplayDivisions: null,
      materialClasses: materialClassSet.materialClasses.map((mc) => ({
        materialClassId: mc.id,
        netWeightScaleDisplayDivisions: null,
      })),
    },
    validate: zodResolver(ManualSampleAnalysisFormSchema),
    validateInputOnBlur: true,
  });
}
