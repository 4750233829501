import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  closestCorners,
  useDroppable,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  Alert,
  Flex,
  Loader,
  Paper,
  PaperProps,
  SimpleGrid,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType, useForm } from '@mantine/form';
import { IconDragDrop } from '@tabler/icons-react';
import { Ref, forwardRef, useState } from 'react';
import { match } from 'ts-pattern';
import { LinkButton } from '../Link';
import { useMaterialClasses } from '../api/materialClass';
import { MaterialClassDTO } from '../rest-client';
import { Router } from '../router';
import cssClasses from './MaterialClassSetForm.module.css';

export interface MaterialClassSetFormValues {
  name: string;
  materialClassIds: string[];
}

export function useMaterialClassSetForm() {
  return useForm<MaterialClassSetFormValues>({
    initialValues: {
      name: '',
      materialClassIds: [],
    },
    validate: {
      name: (name) => (name ? null : 'Name is required'),
      materialClassIds: (ids) =>
        ids.length === 0 ? 'Must have at least one material class' : null,
    },
  });
}

export function MaterialClassFormFields(props: {
  form: UseFormReturnType<MaterialClassSetFormValues>;
  loading?: boolean;
}) {
  const { form, loading = false } = props;
  const materialClassesQuery = useMaterialClasses();
  return (
    <>
      <TextInput
        autoFocus
        label='Name'
        placeholder={loading ? 'Loading...' : 'Material Class Set Name'}
        required
        withAsterisk
        disabled={loading}
        {...form.getInputProps('name')}
      />
      {materialClassesQuery.data?.length === 0 ? (
        <Alert color='yellow' title='No Material Classes'>
          <Stack spacing='xs'>
            No material classes have been defined yet.
            <LinkButton to={Router.MaterialClassCreate()}>
              Add Material Class
            </LinkButton>
          </Stack>
        </Alert>
      ) : undefined}
      <OrderedMaterialClassSetSelect
        value={form.values.materialClassIds}
        onChange={(ids) => form.setFieldValue('materialClassIds', ids)}
      />
    </>
  );
}

export function SortableMaterialClassItem(props: {
  materialClass: MaterialClassDTO;
}) {
  const { materialClass } = props;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: materialClass.id,
    });

  return (
    <MaterialClassItem
      materialClass={materialClass}
      ref={setNodeRef}
      style={{
        transform: CSS.Translate.toString(transform),
        transition,
        userSelect: 'none',
      }}
      {...listeners}
      {...attributes}
    />
  );
}

export interface MaterialClassItemProps extends PaperProps {
  materialClass: MaterialClassDTO;
}

export const MaterialClassItem = forwardRef(function MaterialClassItem(
  props: MaterialClassItemProps,
  ref: Ref<HTMLDivElement>,
) {
  const { materialClass, ...rest } = props;
  return (
    <Paper ref={ref} {...rest} withBorder p='xs' mx='xl'>
      <Text>{materialClass.name}</Text>
    </Paper>
  );
});

export function SortableMaterialClassItemContainer(props: {
  id: string;
  materialClassIds: string[];
  materialClassLookup: Map<string, MaterialClassDTO>;
}) {
  const { id, materialClassIds, materialClassLookup } = props;

  const { setNodeRef } = useDroppable({ id });
  return (
    <SortableContext id={id} items={materialClassIds}>
      <Stack
        ref={setNodeRef}
        spacing='xs'
        py='xs'
        className={cssClasses.sortableWrapper}
      >
        {materialClassIds.map((materialClassId) => {
          const materialClass = materialClassLookup.get(materialClassId);
          return (
            materialClass && (
              <SortableMaterialClassItem
                key={materialClassId}
                materialClass={materialClass}
              />
            )
          );
        })}
      </Stack>
    </SortableContext>
  );
}

interface OrderedMaterialClassSetSelectProps {
  value: string[];
  onChange: (ids: string[]) => void;
}

function OrderedMaterialClassSetSelect(
  props: OrderedMaterialClassSetSelectProps,
) {
  const { value, onChange } = props;

  const materialClassesQuery = useMaterialClasses();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const [activeMaterialClass, setActiveMaterialClass] =
    useState<MaterialClassDTO | null>(null);

  if (materialClassesQuery.data) {
    const materialClasses = materialClassesQuery.data;
    const materialClassLookup = new Map(
      materialClasses.map((mc) => [mc.id, mc]),
    );

    const includedIdSet = new Set(value);
    const excludedIds = materialClasses
      .map((mc) => mc.id)
      .filter((mcId) => !includedIdSet.has(mcId));

    const handleDragStart = (event: DragStartEvent) => {
      const dragStartedOnClass = materialClassLookup.get(
        event.active.id as string,
      );
      setActiveMaterialClass(dragStartedOnClass ?? null);
    };

    const handleDragOver = (event: DragOverEvent) => {
      const { active, over } = event;

      if (!over) return;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const activeContainerId = active.data.current?.sortable.containerId as
        | 'included'
        | 'excluded';
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const overContainerId = (over.data.current?.sortable.containerId ||
        over.id) as 'included' | 'excluded';

      if (activeContainerId !== overContainerId) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const activeIdx = active.data.current?.sortable.index as
          | number
          | undefined;

        if (activeIdx === undefined) return;

        if (overContainerId === 'included') {
          onChange([
            ...value.slice(0, activeIdx),
            active.id as string,
            ...value.slice(activeIdx),
          ]);
        } else {
          onChange(value.filter((cId) => cId !== active.id));
        }
      }
    };

    const handleDragEnd = ({ active, over }: DragEndEvent) => {
      setActiveMaterialClass(null);

      if (!over) return;

      if (active.id !== over.id) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const activeContainer = active.data.current?.sortable.containerId as
          | 'included'
          | 'excluded';
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const overContainer = (over.data.current?.sortable.containerId ||
          over.id) as 'included' | 'excluded';

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const activeIdx = active.data.current?.sortable.index as number;
        const overIdx = match(over.id)
          .with('included', () => value.length)
          .with('excluded', () => excludedIds.length)
          .otherwise(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            () => over.data.current?.sortable.index as number,
          );

        if (activeContainer == overContainer && overContainer === 'excluded') {
          return; // Don't allow reordering of the excluded classes
        }

        if (activeContainer == overContainer && overContainer === 'included') {
          // reorder within included
          onChange(arrayMove(value, activeIdx, overIdx));
          return;
        }
      }
    };

    return (
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}
        onDragCancel={() => setActiveMaterialClass(null)}
      >
        <SimpleGrid cols={3}>
          <Flex direction='column'>
            <Text color='dimmed' size='lg'>
              Included
            </Text>
            <Paper
              withBorder
              className={cssClasses.includedMaterialClassWrapper}
            >
              <SortableMaterialClassItemContainer
                id='included'
                materialClassIds={value}
                materialClassLookup={materialClassLookup}
              />
            </Paper>
          </Flex>

          <div>
            <Text color='dimmed' size='lg'>
              Excluded
            </Text>
            <Paper withBorder>
              <SortableMaterialClassItemContainer
                id='excluded'
                materialClassIds={excludedIds}
                materialClassLookup={materialClassLookup}
              />
            </Paper>
          </div>

          <Flex direction='column' align='center' justify='flex-start'>
            <Stack align='center'>
              <IconDragDrop size='2rem' />
              <Alert color='yellow'>
                Drag and drop material classes to include them. At least one
                material class must be included
              </Alert>
            </Stack>
          </Flex>
        </SimpleGrid>

        <DragOverlay>
          {activeMaterialClass ? (
            <MaterialClassItem
              materialClass={activeMaterialClass}
              shadow='xl'
            />
          ) : null}
        </DragOverlay>
      </DndContext>
    );
  }

  if (materialClassesQuery.isError) {
    throw materialClassesQuery.error;
  }

  // TODO(2317): Skeletonize
  return <Loader variant='bars' />;
}
