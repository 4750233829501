import { Group, Input, Switch } from '@mantine/core';
import { UseFormReturnType, useForm, zodResolver } from '@mantine/form';
import { ZodType, z } from 'zod';
import SwitchAsLabeledInputClasses from '../../Input/SwitchAsLabeledInput.module.css';
import { RecoveryPathEdit } from '../../RecoveryGoal/RecoveryPathEdit';
import { CommoditySelect } from '../CommoditySelect';

export type DefaultCommodityAssignmentFormValues = {
  inputCommodityId: string | null;
  steps: {
    recoveryGoalId: string | null;
    negative: boolean;
  }[];
  outputCommodityId: string | null;
  intermediate: boolean;
};

const DefaultCommodityAssignmentFormSchema: ZodType<DefaultCommodityAssignmentFormValues> =
  z
    .object({
      inputCommodityId: z
        .string({ message: 'Input commodity is required' })
        .uuid(),
      steps: z
        .object({
          recoveryGoalId: z
            .string({ message: 'Recovery goal is required for each step' })
            .uuid(),
          negative: z.boolean(),
        })
        .array()
        .nonempty({ message: 'At least one recovery step is required' }),
      outputCommodityId: z.string().uuid().nullable(),
      intermediate: z.boolean(),
    })
    .refine(
      ({ outputCommodityId, intermediate }) =>
        intermediate && outputCommodityId === null,
      {
        message:
          'Output commodity is required unless the output is marked as intermediate',
        path: ['outputCommodityId'],
      },
    );

export function useDefaultCommodityAssignmentForm() {
  return useForm<DefaultCommodityAssignmentFormValues>({
    initialValues: {
      inputCommodityId: null,
      steps: [{ recoveryGoalId: null, negative: false }],
      outputCommodityId: null,
      intermediate: false,
    },
    validate: zodResolver(DefaultCommodityAssignmentFormSchema),
  });
}

export function DefaultCommodityAssignmentFormFields(props: {
  form: UseFormReturnType<DefaultCommodityAssignmentFormValues>;
}) {
  const { form } = props;

  return (
    <>
      <CommoditySelect
        label='Input Commodity'
        description='The input commodity this default recovery path behavior is predicated upon.'
        required
        {...form.getInputProps('inputCommodityId')}
      />
      <Input.Wrapper
        label='Recovery Path'
        description='The ordered recovery steps performed on the input commodity that result in the output commodity.'
        required
      >
        <Group>
          <RecoveryPathEdit form={form} accessor={(v) => v} />
        </Group>
      </Input.Wrapper>
      <Input.Wrapper
        label='Intermediate Output'
        description='Is the output of the specified recovery steps on the specified input commidity expected to be an intermediate product and not a commodity?'
      >
        <Switch
          size='lg'
          className={SwitchAsLabeledInputClasses.switch}
          checked={form.values.intermediate}
          onChange={(e) => {
            form.setFieldValue('intermediate', e.currentTarget.checked);
            // reset the output commodity
            if (e.currentTarget.checked) {
              form.setFieldValue('outputCommodityId', null);
            }
          }}
        />
      </Input.Wrapper>
      <CommoditySelect
        label='Output Commodity'
        description='The output commodity to assign by default to material processed by the specified recovery path behavior on the specified input commodity.'
        required={!form.values.intermediate}
        disabled={form.values.intermediate}
        placeholder={form.values.intermediate ? '' : undefined}
        {...form.getInputProps('outputCommodityId')}
      />
    </>
  );
}
