import { Alert, Group, Stack } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FormActionButton } from '../Form/FormActionButton';
import { useFormNavBlocker } from '../Form/useFormNavBlocker';
import { useAddMaterialClassSet } from '../api/materialClassSet';
import { MaterialClassSetCreationDTO } from '../rest-client';
import { Router } from '../router';
import {
  MaterialClassFormFields,
  useMaterialClassSetForm,
} from './MaterialClassSetForm';

export function AddMaterialClassSetForm() {
  const form = useFormNavBlocker(useMaterialClassSetForm());
  const addMutation = useAddMaterialClassSet();
  const id = useMemo(() => uuidv4(), []);

  if (addMutation.isError) {
    return (
      <Alert
        maw={500}
        color='red'
        title='Error Creating Material Class Set'
        withCloseButton
        closeButtonLabel='Clear Error'
        onClose={() => addMutation.reset()}
      >
        The material class set may or may not have been saved. You can clear the
        error and try again.
      </Alert>
    );
  }

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        const set: MaterialClassSetCreationDTO = {
          id,
          name: values.name,
          materialClassIds: values.materialClassIds,
        };

        addMutation.mutate(set, {
          onError() {
            showNotification({
              title: 'Error Creating Material Class Set',
              message: `An error occurred creating the material class set`,
              color: 'red',
              icon: <IconX />,
            });
          },
          onSuccess() {
            showNotification({
              title: 'Material Class Set Created',
              message: 'The material class set was created successfully',
              color: 'green',
              icon: <IconCheck />,
            });
            Router.replace('MaterialClassList');
          },
        });
      })}
    >
      <Stack maw={1000}>
        <MaterialClassFormFields form={form} />
        <Alert title='Selection Cannot Be Changed' color='orange'>
          The set of material classes cannot be changed after creation!
        </Alert>
        <Group position='right'>
          <FormActionButton
            action='cancel'
            onClick={() => Router.push('MaterialClassList')}
            loading={!addMutation.isIdle}
          />
          <FormActionButton
            type='submit'
            action='saveCreation'
            loading={!addMutation.isIdle}
            disabled={form.values.materialClassIds.length === 0}
          >
            Add Material Classes
          </FormActionButton>
        </Group>
      </Stack>
    </form>
  );
}
