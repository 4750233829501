import { Loader, Select, SelectProps } from '@mantine/core';
import { ReactNode } from 'react';
import { useFacilityContext } from '../Facility/FacilityContext';
import { useProcesses } from '../api/process';
import { ProcessId } from '../rest-client';

export interface ProcessSelectSpecificProps {
  value: ProcessId | null;
  onChange: (id: ProcessId | null) => void;
  emptyContent: ReactNode;
}

export type ProcessSelectProps = Omit<
  SelectProps,
  keyof ProcessSelectSpecificProps | 'data'
> &
  ProcessSelectSpecificProps;

export function ProcessSelect(props: ProcessSelectProps) {
  const { value, onChange, emptyContent, ...selectProps } = props;
  const facility = useFacilityContext();
  const processesQuery = useProcesses(facility.id);
  if (processesQuery.isLoadingError) {
    throw processesQuery.error;
  }

  if (processesQuery.isLoading) {
    return <Select data={[]} disabled rightSection={<Loader size='xs' />} />;
  }

  const processes = processesQuery.data;

  if (processes.length === 0) return <>{emptyContent}</>;
  return (
    <Select
      label='Process'
      placeholder='Select Process'
      value={value}
      onChange={onChange}
      data={processes.map((process) => ({
        value: process.id,
        label: process.name,
      }))}
      {...selectProps}
    />
  );
}
