import { TextInput } from '@mantine/core';
import { UseFormReturnType, useForm, zodResolver } from '@mantine/form';
import { ZodType, z } from 'zod';
import { CommoditySelect } from '../Commodity/CommoditySelect';
import { FacilitySelect } from '../Facility/FacilitySelect';

export type InternalMaterialSourceFormValues = {
  name: string;
  facilityId: string;
  commodityId: string;
};

const InternalMaterialSourceFormSchema: ZodType<InternalMaterialSourceFormValues> =
  z.object({
    name: z.string().min(1, { message: 'Name is required' }),
    facilityId: z.string().uuid({ message: 'Facility is required' }),
    commodityId: z.string().uuid({ message: 'Commodity is required' }),
  });

export function useInternalMaterialSourceForm() {
  return useForm<InternalMaterialSourceFormValues>({
    initialValues: {
      name: '',
      facilityId: '',
      commodityId: '',
    },
    validate: zodResolver(InternalMaterialSourceFormSchema),
  });
}

export function InternalMaterialSourceFormFields(props: {
  form: UseFormReturnType<InternalMaterialSourceFormValues>;
}) {
  const { form } = props;
  return (
    <>
      <TextInput
        label='Upstream Source Name'
        placeholder='Name'
        autoFocus
        withAsterisk
        {...form.getInputProps('name')}
      />
      {/* TODO: update facility select component */}
      <FacilitySelect
        placeholder='Select facility'
        description='The facility where the upstream source is located.'
        withAsterisk
        {...form.getInputProps('facilityId')}
      />
      <CommoditySelect
        label='Default Commodity'
        placeholder='Select commodity'
        description='The commodity which will be default assigned to all materials sourced from this upstream source.'
        withAsterisk
        {...form.getInputProps('commodityId')}
      />
    </>
  );
}
