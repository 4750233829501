import {
  ActionIcon,
  Alert,
  Button,
  Center,
  Flex,
  Group,
  Paper,
  SegmentedControl,
  Text,
} from '@mantine/core';
import { UseFormReturnType, useForm, zodResolver } from '@mantine/form';
import { useToggle } from '@mantine/hooks';
import { IconAlertTriangle } from '@tabler/icons-react';
import { Dayjs } from 'dayjs';
import { match } from 'ts-pattern';
import ContainerSelect from '../Container/ContainerSelect';
import { useFacilityContext } from '../Facility/FacilityContext';
import { DeleteIcon } from '../Icons';
import ContainerFullnessSwitch from '../Input/ContainerFullnessSwitch';
import { InvalidVisibilityToggle } from '../Input/InvalidVisibilityToggle';
import { TruckLoadSelect } from '../TruckLoad/TruckLoadSelect';

import { ZodType, z } from 'zod';
import cssClasses from './PartitionForm.module.css';

interface ContainerPartitionDestination {
  containerId: string | null;
  filled: boolean;
}

interface TruckLoadPartitionDestination {
  truckLoadId: string | null;
}

export type PartitionFormValues = {
  containerDestinations: ContainerPartitionDestination[];
  truckLoadDestinations: TruckLoadPartitionDestination[];
  destinationMode: 'truck-load' | 'container';
};

const PartitionTransferFormSchema: ZodType<PartitionFormValues> = z
  .object({
    containerDestinations: z
      .object({
        containerId: z.string().uuid(),
        filled: z.boolean(),
      })
      .array(),
    truckLoadDestinations: z
      .object({
        truckLoadId: z.string().uuid(),
      })
      .array(),
    destinationMode: z.enum(['truck-load', 'container']),
  })
  .refine(
    ({ containerDestinations, truckLoadDestinations, destinationMode }) =>
      match(destinationMode)
        .with(
          'container',
          () =>
            containerDestinations.length > 0 &&
            truckLoadDestinations.length === 0,
        )
        .with(
          'truck-load',
          () =>
            truckLoadDestinations.length > 0 &&
            containerDestinations.length === 0,
        )
        .exhaustive(),
    {
      message: 'There must be at least one container/truckload selected',
      path: ['destinationMode'],
    },
  );

export function usePartitionForm() {
  return useForm<PartitionFormValues>({
    initialValues: {
      containerDestinations: [],
      truckLoadDestinations: [],
      destinationMode: 'container',
    },
    validate: zodResolver(PartitionTransferFormSchema),
  });
}

export function PartitionFormFields(props: {
  form: UseFormReturnType<PartitionFormValues>;
  timestamp: Dayjs | undefined;
}) {
  const { form, timestamp } = props;
  const facility = useFacilityContext();
  const [showFullContainers, toggleShowFullContainers] = useToggle();

  const noContainersSelected = form.values.containerDestinations.length === 0;
  const noTruckloadsSelected = form.values.truckLoadDestinations.length === 0;

  const containerFields = (
    <>
      {noContainersSelected && (
        <Paper withBorder shadow='lg' p='sm'>
          <Center>
            <Text color='dimmed'>Add a container to get started</Text>
          </Center>
        </Paper>
      )}
      {form.values.containerDestinations.map((_, i) => (
        <Flex key={i} gap='md'>
          <ContainerSelect
            timestamp={timestamp}
            facilityId={facility.id}
            hideEmpty={false}
            hideFull={!showFullContainers}
            hiddenContainerIds={
              // Hide all other selected containers
              new Set(
                form.values.containerDestinations
                  .filter((d, j) => j !== i)
                  .flatMap((d) =>
                    d.containerId !== null ? [d.containerId] : [],
                  ),
              )
            }
            label={
              'Destination Container' +
              (form.values.containerDestinations.length > 1 ? ` ${i + 1}` : '')
            }
            required
            className={cssClasses.partitionFormControl}
            {...form.getInputProps(`containerDestinations.${i}.containerId`)}
          />
          <ContainerFullnessSwitch
            variant='destination'
            orientation='horizontal'
            size='lg'
            {...form.getInputProps(`containerDestinations.${i}.filled`, {
              type: 'checkbox',
            })}
            inputWrapperProps={{
              label: 'Filled',
            }}
          />
          <ActionIcon
            color='red'
            size='lg'
            onClick={() => form.removeListItem('containerDestinations', i)}
            style={{ alignSelf: 'flex-end' }}
          >
            <DeleteIcon />
          </ActionIcon>
        </Flex>
      ))}
    </>
  );

  const truckLoadFields = (
    <>
      {noTruckloadsSelected && (
        <Paper withBorder shadow='lg' p='sm'>
          <Center>
            <Text color='dimmed'>Add a truck load to get started</Text>
          </Center>
        </Paper>
      )}
      {form.values.truckLoadDestinations.map((_, i) => (
        <Group key={i} mt='xs'>
          <TruckLoadSelect
            timestamp={timestamp}
            className={cssClasses.partitionFormControl}
            {...form.getInputProps(`truckLoadDestinations.${i}.truckLoadId`)}
          />
          <ActionIcon
            color='red'
            onClick={() => form.removeListItem('truckLoadDestinations', i)}
          >
            <DeleteIcon size='1rem' />
          </ActionIcon>
        </Group>
      ))}
    </>
  );

  const mode = form.values.destinationMode;

  const addButtonAction = match(mode)
    .with(
      'container',
      () => () =>
        form.insertListItem('containerDestinations', {
          containerId: null,
          filled: false,
        }),
    )
    .with(
      'truck-load',
      () => () =>
        form.insertListItem('truckLoadDestinations', {
          truckLoadId: null,
        }),
    )
    .exhaustive();
  return (
    <>
      <Flex align='end' gap='md'>
        <SegmentedControl
          data={[
            { label: 'Truck Loads', value: 'truck-load' },
            { label: 'Containers', value: 'container' },
          ]}
          className={cssClasses.partitionFormControl}
          color={
            !noContainersSelected || !noTruckloadsSelected ? 'gray' : 'blue'
          }
          disabled={!noContainersSelected || !noTruckloadsSelected}
          {...form.getInputProps('destinationMode')}
        />

        <InvalidVisibilityToggle
          labelVariant='top'
          label='Show Full Containers'
          value={showFullContainers}
          onToggle={toggleShowFullContainers}
          actionIconProps={{
            size: 'lg',
            style: { alignSelf: 'center' },
          }}
          hidden={mode === 'truck-load'}
        />
      </Flex>

      {match(mode)
        .with('container', () => containerFields)
        .with('truck-load', () => truckLoadFields)
        .exhaustive()}

      <Button onClick={addButtonAction} variant='outline'>
        Add{' '}
        {match(mode)
          .with('container', () => 'Container')
          .with('truck-load', () => 'Truck Load')
          .exhaustive()}
      </Button>

      {showFullContainers ? (
        <Alert color='orange' icon={<IconAlertTriangle />} title=''>
          Creating a transfer with a full destination will result in an ledger
          error. You can still create the transfer, but the error will need to
          be corrected later.
        </Alert>
      ) : undefined}
    </>
  );
}
