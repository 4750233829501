import dayjs from 'dayjs';
import { useFacilityContext } from '../Facility/FacilityContext';
import { DrawerForm, DrawerFormDrawerProps } from '../Form/DrawerForm';
import {
  useContainerTransfer,
  usePatchContainerTransfer,
} from '../api/containerTransfer';
import { useSetFormInitialValuesFromQuery } from '../lib/useSetFormInitialValuesFromQuery';
import {
  MaterialContainerTransferId,
  MaterialContainerTransferPatchDTO,
} from '../rest-client';
import {
  ContainerTransferFormFields,
  ContainerTransferFormValues,
  useContainerTransferForm,
} from './ContainerTransferForm';

export type EditContainerTransferDrawerFormProps = {
  transferId: MaterialContainerTransferId;
  onSuccess?: (transfer: MaterialContainerTransferPatchDTO) => void;
} & DrawerFormDrawerProps;

export function EditContainerTransferDrawerForm(
  props: EditContainerTransferDrawerFormProps,
) {
  const { transferId, onSuccess, onClose, ...drawerFormDrawerProps } = props;

  const facility = useFacilityContext();
  const {
    data: transfer,
    isLoadingError,
    error,
  } = useContainerTransfer(transferId);
  const patchMutation = usePatchContainerTransfer(transferId);
  const form = useContainerTransferForm({});

  useSetFormInitialValuesFromQuery(
    form,
    transfer && {
      timestamp: dayjs.utc(transfer.effectiveTimestamp).tz(facility.timeZoneId),
      sourceContainerId: transfer.sourceContainerId,
      destinationContainerId: transfer.destinationContainerId,
      sourceEmptied: transfer.sourceEmptied,
      destinationFilled: transfer.destinationFilled,
    },
  );

  if (isLoadingError) {
    throw error;
  }

  const formValuesToDto = ({
    timestamp,
    sourceContainerId,
    sourceEmptied,
    destinationContainerId,
    destinationFilled,
  }: ContainerTransferFormValues) => {
    if (sourceContainerId === null || destinationContainerId === null) {
      throw new Error(
        'Source container and destination containet cannot be null ',
      );
    }

    const transferPatch: MaterialContainerTransferPatchDTO = {
      ...(form.isDirty('sourceContainerId') && { sourceContainerId }),
      ...(form.isDirty('sourceEmptied') && { sourceEmptied }),
      ...(form.isDirty('destinationContainerId') && {
        destinationContainerId,
      }),
      ...(form.isDirty('destinationFilled') && { destinationFilled }),
      ...(form.isDirty('timestamp') && {
        transferTime: timestamp.utc().toISOString(),
      }),
    };

    return transferPatch;
  };

  return (
    <DrawerForm
      entityName='Container Material Transfer'
      form={form}
      drawerFormVariant='edit'
      mutation={patchMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      onClose={onClose}
      {...drawerFormDrawerProps}
    >
      <ContainerTransferFormFields form={form} containerSelectVariant='name' />
    </DrawerForm>
  );
}
