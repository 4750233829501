import dayjs from 'dayjs';
import { Dispatch, SetStateAction } from 'react';
import { match } from 'ts-pattern';
import { ContainerIdName } from '../../Container/ContainerIdName';
import { SaleIcon } from '../../Icons';
import { SoldMaterialIdName } from '../../SoldMaterial/SoldMaterialIdName';
import { TruckLoadIdName } from '../../TruckLoad/TruckLoadIdName';
import { CalendarDateTime } from '../../common';
import {
  MaterialContainerSaleTransferDTO,
  MaterialTransactionDTO,
  MaterialTruckLoadSaleTransferDTO,
} from '../../rest-client';
import { CurrentDrawerState } from '../InventoryLedgerPage.tsx';
import { InventoryLedgerRowTemplate } from '../InventoryLedgerRowTemplate';
import {
  TransactionStatus,
  useInventoryLedgerStatusContext,
} from '../LedgerStatusContext';
import { EventTypeCellTemplate } from '../TransactionTypeCell';
import { ContainerTransferRow } from './ContainerTransferRow';
import { FeedFlowGroupRow } from './FeedFlowGroupRow';
import { InternalSinkContainerTransferRow } from './InternalSinkContainerTransferRow';
import { InternallySourcedMaterialPartitionRow } from './InternallySourcedMaterialPartitionRow';
import { ProcessBufferTransferRow } from './ProcessBufferTransferRow';
import { PurchasedMaterialPartitionRow } from './PurchasedMaterialPartitionRow';
import { TransactionStatusExplanationRows } from './TransactionStatusExplanationRows';
import { TruckLoadTransferRow } from './TruckLoadTransferRow';

function SaleTypeCell() {
  return (
    <EventTypeCellTemplate icon={<SaleIcon />} color={'green'} name='Sale' />
  );
}

export function ContainerSaleTransferRow(props: {
  sale: MaterialContainerSaleTransferDTO;
  status: TransactionStatus;
  id: string;
}) {
  const { sale, status, id } = props;

  return (
    <InventoryLedgerRowTemplate
      id={id}
      entryStatus={status}
      date={<CalendarDateTime iso8601={sale.effectiveTimestamp} />}
      eventType={<SaleTypeCell />}
      source={
        <ContainerIdName
          containerId={sale.containerId}
          variant='icon-name-link'
          time={dayjs.utc(sale.effectiveTimestamp)}
        />
      }
      destination={
        <SoldMaterialIdName
          soldMaterialId={sale.soldMaterialId}
          variant='icon-name-link'
        />
      }
      actions={undefined} // TODO(1809): Actions
    />
  );
}

export function TruckLoadSaleTransferRow(props: {
  id: string;
  sale: MaterialTruckLoadSaleTransferDTO;
  status: TransactionStatus;
}) {
  const { sale, status, id } = props;

  return (
    <InventoryLedgerRowTemplate
      id={id}
      entryStatus={status}
      date={<CalendarDateTime iso8601={sale.effectiveTimestamp} />}
      eventType={<SaleTypeCell />}
      source={
        <TruckLoadIdName
          truckLoadId={sale.truckLoadId}
          variant='icon-name-link'
        />
      }
      destination={
        <SoldMaterialIdName
          soldMaterialId={sale.soldMaterialId}
          variant='icon-name-link'
        />
      }
      actions={undefined} // TODO(1809): Actions
    />
  );
}

export interface TransactionTableRowProps {
  txn: MaterialTransactionDTO;
  setCurrentDrawer: Dispatch<SetStateAction<CurrentDrawerState>>;
}

export function TransactionRow({
  txn,
  setCurrentDrawer,
}: TransactionTableRowProps) {
  const ledgerStatus = useInventoryLedgerStatusContext();
  const status = ledgerStatus.transactionStatus(txn);
  const { ledgerId } = txn;

  const primaryRow = match(txn)
    .with({ kind: 'PurchasedMaterialPartition' }, (partition) => (
      <PurchasedMaterialPartitionRow
        partition={partition}
        status={status}
        id={ledgerId}
      />
    ))
    .with({ kind: 'InternallySourcedMaterialPartition' }, (partition) => (
      <InternallySourcedMaterialPartitionRow
        partition={partition}
        status={status}
        id={ledgerId}
      />
    ))
    .with({ kind: 'MaterialTruckLoadTransfer' }, (truckLoadTransfer) => (
      <TruckLoadTransferRow
        transfer={truckLoadTransfer}
        status={status}
        id={ledgerId}
      />
    ))
    .with({ kind: 'ContainerTransfer' }, (containerTransfer) => (
      <ContainerTransferRow
        transfer={containerTransfer}
        status={status}
        id={ledgerId}
      />
    ))
    .with({ kind: 'ContainerSaleTransfer' }, (sale) => (
      <ContainerSaleTransferRow sale={sale} status={status} id={ledgerId} />
    ))
    .with({ kind: 'TruckLoadSaleTransfer' }, (sale) => (
      <TruckLoadSaleTransferRow sale={sale} status={status} id={ledgerId} />
    ))
    .with({ kind: 'InternalSinkContainerTransfer' }, (sinkTransfer) => (
      <InternalSinkContainerTransferRow
        transfer={sinkTransfer}
        status={status}
        id={ledgerId}
      />
    ))
    .with({ kind: 'BufferTransfer' }, (bufferTransfer) => (
      <ProcessBufferTransferRow
        bufferTransferEvent={bufferTransfer}
        setCurrentDrawer={setCurrentDrawer}
      />
    ))
    .with({ kind: 'ProcessFeedstockTransaction' }, ({ feedFlowGroup }) => (
      <FeedFlowGroupRow feedFlowGroup={feedFlowGroup} />
    ))
    .with({ kind: 'ContainerOutputPortFlowGroupTransaction' }, () => undefined)
    .with({ kind: 'DroppedOutputPortFlowGroupTranasction' }, () => undefined)
    .exhaustive();

  const statusExplanationRows = (
    <TransactionStatusExplanationRows status={status} />
  );

  return (
    <>
      {primaryRow}
      {statusExplanationRows}
    </>
  );
}
