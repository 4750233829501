import { Alert, Badge, Group, Text } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { match } from 'ts-pattern';
import { useFacilityContext } from '../../Facility/FacilityContext';
import { openDeleteEntityConfirmModal } from '../../Form/useDeleteEntityModal.tsx';
import {
  FullContainerIcon,
  OutputContainerChangeIcon,
  OutputContainerRemovedIcon,
  PartiallyFullContainerIcon,
} from '../../Icons';
import { ProcessName } from '../../Process/ProcessName';
import { ContainerName } from '../../Repository/RepositoryName';
import { useDeleteOutputContainerChange } from '../../api/outputContainerChange.ts';
import { CalendarDateTime } from '../../common';
import {
  OutputContainerChangeDTO,
  OutputContainerChangeErrorDTO,
} from '../../rest-client';
import { DeleteMenuItem } from '../DeleteMenuItem.tsx';
import { EditMenuItem } from '../EditMenuItem.tsx';
import { CurrentDrawerState } from '../InventoryLedgerPage.tsx';
import { InventoryLedgerRowTemplate } from '../InventoryLedgerRowTemplate';
import { useInventoryLedgerStatusContext } from '../LedgerStatusContext';
import { RowActionsMenu } from '../RowActionsMenu.tsx';

export function OutputContainerChangeRow(props: {
  outputContainerChange: OutputContainerChangeDTO;
  setCurrentDrawer: Dispatch<SetStateAction<CurrentDrawerState>>;
}) {
  const { outputContainerChange, setCurrentDrawer } = props;
  const facility = useFacilityContext();
  const ledgerStatus = useInventoryLedgerStatusContext();
  const status = ledgerStatus.outputContainerChangeStatus(
    outputContainerChange,
  );
  const timestamp = outputContainerChange.effectiveTimestamp;

  const deleteMutation = useDeleteOutputContainerChange();
  const openDeleteModal = openDeleteEntityConfirmModal(
    'Output Container Change',
    () => {
      deleteMutation.mutate(
        { outputContainerChangeId: outputContainerChange.id },
        {
          onError() {
            showNotification({
              title: 'Error Deleting Output Container Change',
              message:
                'An error occurred deleting the output container change.',
              color: 'red',
              icon: <IconX />,
            });
          },
          onSuccess() {
            showNotification({
              title: 'Output Container Change Deleted',
              message:
                'The output container change has successfully been deleted.',
              color: 'green',
              icon: <IconCheck />,
            });
          },
          onSettled() {
            deleteMutation.reset();
          },
        },
      );
    },
  );

  const renderEventTypeCell = useCallback(
    () => (
      <Group spacing='xs'>
        {outputContainerChange.container === null ? (
          <OutputContainerRemovedIcon />
        ) : (
          <OutputContainerChangeIcon />
        )}
        <Badge color='yellow'>
          {outputContainerChange.container === null
            ? 'Output Container Removed'
            : 'Output Container Replaced'}
        </Badge>
      </Group>
    ),
    [outputContainerChange],
  );

  const sourceCell =
    outputContainerChange.container === null ? (
      <Group spacing='xs'>
        <OutputContainerRemovedIcon />
        <Text c='dimmed'>No Container</Text>
      </Group>
    ) : (
      <Group spacing='xs'>
        <OutputContainerChangeIcon />
        <ContainerName
          container={outputContainerChange.container}
          time={dayjs
            .utc(outputContainerChange.effectiveTimestamp)
            .tz(facility.timeZoneId)}
        />
        {outputContainerChange.previousContainerFilled ? (
          <PartiallyFullContainerIcon />
        ) : (
          <FullContainerIcon />
        )}
      </Group>
    );

  const destinationCell = (
    <Group spacing={0}>
      <ProcessName process={outputContainerChange.process} withIcon />
      <Text mx='0.5ch' weight={700} span>
        •
      </Text>
      <Text>{outputContainerChange.outputPort.name}</Text>
    </Group>
  );

  const actionCell = (
    <RowActionsMenu>
      <EditMenuItem
        onClick={() =>
          setCurrentDrawer({
            drawerName: 'Edit Output Container Change',
            id: outputContainerChange.id,
          })
        }
      />
      <DeleteMenuItem onClick={openDeleteModal} />
    </RowActionsMenu>
  );

  let errorExplanationContent = null;
  if (status.status === 'conflict') {
    errorExplanationContent = (
      <>
        {status.errors.map((error, i) => (
          <OutputContainerChangeErrorExplanation
            outputContainerChangeError={error}
            key={i}
          />
        ))}
      </>
    );
  }

  return (
    <>
      <InventoryLedgerRowTemplate
        eventType={renderEventTypeCell()}
        date={<CalendarDateTime iso8601={timestamp} />}
        entryStatus={status}
        source={sourceCell}
        destination={destinationCell}
        actions={actionCell}
      />
      {errorExplanationContent}
    </>
  );
}

function OutputContainerChangeErrorExplanation({
  outputContainerChangeError,
}: {
  outputContainerChangeError: OutputContainerChangeErrorDTO;
}) {
  return match(outputContainerChangeError)
    .with({ kind: 'SimultaneousOutputContainerChangeError' }, (error) => (
      <Alert color='red' title='Output Container Change Concurrency Conflict'>
        This output container change conflicts with another output container
        change that occurs at the same time on the same process output port:{' '}
        <ProcessName process={error.outputContainerChangeA.process} />{' '}
        {error.outputContainerChangeA.outputPort.name}.
      </Alert>
    ))
    .exhaustive();
}
