import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DrawerForm, DrawerFormDrawerProps } from '../Form/DrawerForm';
import { useCreateInternalMaterialSink } from '../api/internalMaterialSink';
import { InternalMaterialSinkCreationDTO } from '../rest-client';
import { Router } from '../router';
import {
  InternalMaterialSinkFormFields,
  InternalMaterialSinkFormValues,
  useInternalMaterialSinkForm,
} from './InternalMaterialSinkForm';

export type AddInternalMaterialSinkDrawerFormProps = {
  onSuccess?: (internalMaterialSink: InternalMaterialSinkCreationDTO) => void;
} & DrawerFormDrawerProps;

export function AddInternalMaterialSinkDrawerForm(
  props: AddInternalMaterialSinkDrawerFormProps,
) {
  const {
    onSuccess = (internalMaterialSink: InternalMaterialSinkCreationDTO) => {
      Router.replace('InternalSinkDetail', {
        internalMaterialSinkId: internalMaterialSink.id,
      });
    },
    onClose,
    ...drawerFormDrawerProps
  } = props;

  const createMutation = useCreateInternalMaterialSink();
  const form = useInternalMaterialSinkForm();
  const internalMaterialSinkId = useMemo(() => uuidv4(), []);

  const formValuesToDto = ({
    name,
    facilityId,
  }: InternalMaterialSinkFormValues) => {
    const internalMaterialSink: InternalMaterialSinkCreationDTO = {
      id: internalMaterialSinkId,
      name,
      facilityId,
    };
    return internalMaterialSink;
  };

  return (
    <DrawerForm
      entityName='Material Export Destination'
      form={form}
      drawerFormVariant='create'
      mutation={createMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      onClose={onClose}
      {...drawerFormDrawerProps}
    >
      <InternalMaterialSinkFormFields form={form} />
    </DrawerForm>
  );
}
