import { Alert, Badge, Group, Text } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { Dispatch, SetStateAction } from 'react';
import { match } from 'ts-pattern';
import { openDeleteEntityConfirmModal } from '../../Form/useDeleteEntityModal.tsx';
import { ProcessBufferDepletionIcon } from '../../Icons';
import { ProcessIdName } from '../../Process/ProcessIdName';
import { useDeleteProcessBufferDepletion } from '../../api/processBufferDepletion.ts';
import { CalendarDateTime } from '../../common';
import {
  BufferDepletionErrorDTO,
  ProcessBufferDepletionDTO,
} from '../../rest-client';
import { DeleteMenuItem } from '../DeleteMenuItem.tsx';
import { EditMenuItem } from '../EditMenuItem.tsx';
import { CurrentDrawerState } from '../InventoryLedgerPage.tsx';
import { InventoryLedgerRowTemplate } from '../InventoryLedgerRowTemplate';
import { useInventoryLedgerStatusContext } from '../LedgerStatusContext';
import { RowActionsMenu } from '../RowActionsMenu.tsx';

export function ProcessBufferDepletionRow(props: {
  processBufferDepletion: ProcessBufferDepletionDTO;
  setCurrentDrawer: Dispatch<SetStateAction<CurrentDrawerState>>;
}) {
  const { processBufferDepletion, setCurrentDrawer } = props;
  const ledgerStatus = useInventoryLedgerStatusContext();

  const status = ledgerStatus.bufferDepletionStatus(processBufferDepletion);
  const timestamp = processBufferDepletion.effectiveTimestamp;

  const deleteMutation = useDeleteProcessBufferDepletion();
  const openDeleteModal = openDeleteEntityConfirmModal(
    'Feedstock Depletion',
    () => {
      deleteMutation.mutate(
        { processBufferDepletionId: processBufferDepletion.id },
        {
          onError() {
            showNotification({
              title: 'Error Deleting Feedstock Depletion',
              message: 'An error occurred deleting the feedstock depletion.',
              color: 'red',
              icon: <IconX />,
            });
          },
          onSuccess() {
            showNotification({
              title: 'Feedstock Depletion Deleted',
              message: 'The feedstock depletion has successfully been deleted.',
              color: 'green',
              icon: <IconCheck />,
            });
          },
          onSettled() {
            deleteMutation.reset();
          },
        },
      );
    },
  );

  const eventTypeCell = (
    <Group spacing='xs'>
      <ProcessBufferDepletionIcon />
      <Badge color='yellow'>Feedstock Depletion</Badge>
    </Group>
  );

  const sourceCell = (
    <ProcessIdName processId={processBufferDepletion.processId} withIcon />
  );

  const destinationCell = (
    <Group spacing='xs'>
      <ProcessBufferDepletionIcon />
      <Text c='dimmed'>Feedstock Depleted</Text>
    </Group>
  );

  const actionCell = (
    <RowActionsMenu>
      <EditMenuItem
        onClick={() =>
          setCurrentDrawer({
            drawerName: 'Edit Feedstock Depletion',
            id: processBufferDepletion.id,
          })
        }
      />
      <DeleteMenuItem onClick={openDeleteModal} />
    </RowActionsMenu>
  );

  let errorExplanationContent = null;
  if (status.status === 'conflict') {
    errorExplanationContent = (
      <>
        {status.errors.map((error, i) => (
          <ProcessBufferDepletionErrorExplaination
            bufferDepletionError={error}
            key={i}
          />
        ))}
      </>
    );
  }

  return (
    <>
      <InventoryLedgerRowTemplate
        eventType={eventTypeCell}
        date={<CalendarDateTime iso8601={timestamp} />}
        entryStatus={status}
        source={sourceCell}
        destination={destinationCell}
        actions={actionCell}
      />
      {errorExplanationContent}
    </>
  );
}

function ProcessBufferDepletionErrorExplaination({
  bufferDepletionError,
}: {
  bufferDepletionError: BufferDepletionErrorDTO;
}) {
  return match(bufferDepletionError)
    .with({ kind: 'SimultaneousBufferDepletionError' }, (error) => (
      <Alert
        color='red'
        title='Process Feedstock Depletion Concurrency Conflict'
      >
        The feedstock depletion event fonclits with another feedstock depletion
        event for the same process:{' '}
        <ProcessIdName processId={error.depletionA.processId} />.
      </Alert>
    ))
    .exhaustive();
}
