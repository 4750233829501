import { Box, Button, Drawer, Group, Stack, Title } from '@mantine/core';
import { ScrollAreaComponent } from '@mantine/core/lib/Drawer/Drawer.context';
import { useState } from 'react';
import { AppPage } from '../App/AppPage.tsx';
import AddContainerTransferButton from '../ContainerTransfer/AddContainerTransferButton.tsx';
import {
  BufferTransferIcon,
  OutputContainerChangeIcon,
  ProcessBufferDepletionIcon,
  ProcessBufferRestorationIcon,
  TruckLoadIcon,
} from '../Icons.tsx';
import AddInternalSinkContainerTransferButton from '../InternalSinkContainerTransfer/AddInternalSinkContainerTransferButton.tsx';
import { AddOutputContainerChangeForm } from '../OutputContainerChange/AddOutputContainerChangeForm.tsx';
import { EditOutputContainerChangeForm } from '../OutputContainerChange/EditOutputContainerChangeForm.tsx';
import { AddProcessBufferDepletionForm } from '../ProcessBufferDepletion/AddProcessBufferDepletionForm.tsx';
import { EditProcessBufferDepletionForm } from '../ProcessBufferDepletion/EditProcessBufferDepletionForm.tsx';
import { AddProcessBufferRestorationForm } from '../ProcessBufferRestoration/AddProcessBufferRestorationForm.tsx';
import { EditProcessBufferRestorationForm } from '../ProcessBufferRestoration/EditProcessBufferRestorationForm.tsx';
import { AddProcessBufferTransferForm } from '../ProcessBufferTransfer/AddProcessBufferTransferForm.tsx';
import { EditProcessBufferTransferForm } from '../ProcessBufferTransfer/EditProcessBufferTransferForm.tsx';
import { AddTruckLoadTransferForm } from '../TruckLoad/AddTruckLoadTransferForm.tsx';
import { InventoryLedger } from './InventoryLedger.tsx';

export const inventoryLedgerDrawers = {
  ['Add Feedstock Transfer']: AddProcessBufferTransferForm,
  ['Edit Feedstock Transfer']: EditProcessBufferTransferForm,
  ['Add Feedstock Depletion']: AddProcessBufferDepletionForm,
  ['Edit Feedstock Depletion']: EditProcessBufferDepletionForm,
  ['Add Feedstock Restoration']: AddProcessBufferRestorationForm,
  ['Edit Feedstock Restoration']: EditProcessBufferRestorationForm,
  ['Add Output Container Change']: AddOutputContainerChangeForm,
  ['Edit Output Container Change']: EditOutputContainerChangeForm,
  ['Add Truck Load Transfer']: AddTruckLoadTransferForm,
} as const;

export interface CurrentDrawerState {
  drawerName?: keyof typeof inventoryLedgerDrawers;
  id?: string;
}

export function InventoryLedgerPage() {
  const [currentDrawer, setCurrentDrawer] = useState<CurrentDrawerState>({});
  const closeDrawer = () => setCurrentDrawer({});

  return (
    <AppPage title='Inventory Ledger'>
      <AppPage.Section>
        {Object.entries(inventoryLedgerDrawers).map(([name, Component]) => (
          <Drawer
            key={name}
            size='md'
            opened={currentDrawer.drawerName === name}
            onClose={closeDrawer}
            title={name}
            position='right'
            styles={{ inner: { height: '100%' } }}
            scrollAreaComponent={Box as ScrollAreaComponent}
          >
            <Component
              onSuccess={closeDrawer}
              onCancel={closeDrawer}
              modelId={currentDrawer.id as unknown as string}
            />
          </Drawer>
        ))}
        <Stack>
          <Group position='apart'>
            <Title order={2}>History</Title>
            <Group>
              <AddContainerTransferButton
                addContainerTransferDrawerFormProps={{}}
              />
              <Button
                onClick={() =>
                  setCurrentDrawer({
                    drawerName: 'Add Feedstock Transfer',
                  })
                }
                leftIcon={<BufferTransferIcon />}
              >
                Add Feedstock Transfer
              </Button>
              <Button
                onClick={() =>
                  setCurrentDrawer({
                    drawerName: 'Add Feedstock Depletion',
                  })
                }
                leftIcon={<ProcessBufferDepletionIcon />}
              >
                Add Feedstock Depletion
              </Button>
              <Button
                onClick={() =>
                  setCurrentDrawer({
                    drawerName: 'Add Feedstock Restoration',
                  })
                }
                leftIcon={<ProcessBufferRestorationIcon />}
              >
                Add Feedstock Restoration
              </Button>
              <Button
                onClick={() =>
                  setCurrentDrawer({
                    drawerName: 'Add Output Container Change',
                  })
                }
                leftIcon={<OutputContainerChangeIcon />}
              >
                Add Output Container Change
              </Button>
              <Button
                onClick={() =>
                  setCurrentDrawer({
                    drawerName: 'Add Truck Load Transfer',
                  })
                }
                leftIcon={<TruckLoadIcon />}
              >
                Add Truck Load Transfer
              </Button>
              <AddInternalSinkContainerTransferButton
                addInternalSinkTransferDrawerFormProps={{}}
              />
            </Group>
          </Group>
          <InventoryLedger setCurrentDrawer={setCurrentDrawer} />
        </Stack>
      </AppPage.Section>
    </AppPage>
  );
}
