import { Alert, Group, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useFacilityContext } from '../Facility/FacilityContext';
import { SelectedFacilityRequiredDateTimePicker } from '../FacilityDateTimePicker';
import { FormActionButton } from '../Form/FormActionButton';
import { ProcessSelect } from '../Process/ProcessSelect';
import {
  usePatchProcessBufferDepletion,
  useProcessBufferDepletion,
} from '../api/processBufferDepletion';
import { useSetFormInitialValuesFromQuery } from '../lib/useSetFormInitialValuesFromQuery';
import { ProcessBufferDepletionPatchDTO } from '../rest-client';
import { ProcessBufferDepletionFormValues } from './AddProcessBufferDepletionForm';

export interface EditProcessBufferDepletionFormProps {
  modelId: string;
  onSuccess: () => void;
  onCancel: () => void;
}

export function EditProcessBufferDepletionForm(
  props: EditProcessBufferDepletionFormProps,
) {
  const { modelId, onSuccess, onCancel } = props;

  const {
    data: processBufferDepletion,
    isLoadingError,
    error,
  } = useProcessBufferDepletion(modelId);
  const patchMutation = usePatchProcessBufferDepletion();
  const facility = useFacilityContext();
  const form = useForm<ProcessBufferDepletionFormValues>({});

  useSetFormInitialValuesFromQuery(
    form,
    processBufferDepletion && {
      processId: processBufferDepletion.processId,
      depletedAt: dayjs
        .utc(processBufferDepletion.effectiveTimestamp)
        .tz(facility.timeZoneId),
    },
  );

  if (isLoadingError) {
    throw error;
  }

  if (patchMutation.isError) {
    return (
      <Alert
        color='red'
        title='Error Updating Feedstock Depletion'
        withCloseButton
        onClose={() => patchMutation.reset()}
      >
        Your changes to the feedstock depletion may or may not have been saved.
        You can clear the error and try again.
      </Alert>
    );
  }

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        if (values.processId === null) {
          throw new Error();
        }

        const patch: ProcessBufferDepletionPatchDTO = {
          ...(form.isDirty('depletedAt') && {
            effectiveTimestamp: values.depletedAt.utc().toISOString(),
          }),
        };

        patchMutation.mutate(
          { processBufferDepletionId: modelId, patch },
          {
            onError() {
              showNotification({
                title: 'Error Updating Feedstock Depletion',
                message: `An error occurred updating the feedstock depletion.`,
                color: 'red',
                icon: <IconX />,
              });
            },
            onSuccess() {
              onSuccess();
              showNotification({
                title: 'Feedstock Depletion Updated',
                message: 'The feedstock depletion was successfully updated.',
                color: 'green',
                icon: <IconCheck />,
              });
            },
          },
        );
      })}
    >
      <Stack>
        <SelectedFacilityRequiredDateTimePicker
          required
          withAsterisk
          label='Time Feedstock Material was Removed'
          maxDate={dayjs()}
          {...form.getInputProps('depletedAt')}
        />

        <ProcessSelect
          disabled
          label='Process'
          emptyContent={
            <Alert title='No Processes' color='red'>
              Cannot create a feedstock depletion without a process.
            </Alert>
          }
          {...form.getInputProps('processId')}
        />

        <Group position='right' mt='md'>
          <FormActionButton
            action='cancel'
            onClick={onCancel}
            loading={patchMutation.isLoading}
          >
            Cancel
          </FormActionButton>
          <FormActionButton
            type='submit'
            action='saveEdits'
            loading={patchMutation.isLoading}
          >
            Save Feedstock Depletion
          </FormActionButton>
        </Group>
      </Stack>
    </form>
  );
}
