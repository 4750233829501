import { Alert, Badge, Group, Text } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { Dispatch, SetStateAction } from 'react';
import { match } from 'ts-pattern';
import { openDeleteEntityConfirmModal } from '../../Form/useDeleteEntityModal.tsx';
import { ProcessBufferRestorationIcon } from '../../Icons';
import { ProcessIdName } from '../../Process/ProcessIdName';
import { useDeleteProcessBufferRestoration } from '../../api/processBufferRestoration.ts';
import { CalendarDateTime } from '../../common';
import {
  BufferRestorationErrorDTO,
  ProcessBufferRestorationDTO,
} from '../../rest-client';
import { DeleteMenuItem } from '../DeleteMenuItem.tsx';
import { EditMenuItem } from '../EditMenuItem.tsx';
import { CurrentDrawerState } from '../InventoryLedgerPage.tsx';
import { InventoryLedgerRowTemplate } from '../InventoryLedgerRowTemplate';
import { useInventoryLedgerStatusContext } from '../LedgerStatusContext';
import { RowActionsMenu } from '../RowActionsMenu.tsx';

export function ProcessBufferRestorationRow(props: {
  processBufferRestoration: ProcessBufferRestorationDTO;
  setCurrentDrawer: Dispatch<SetStateAction<CurrentDrawerState>>;
}) {
  const { processBufferRestoration, setCurrentDrawer } = props;
  const ledgerStatus = useInventoryLedgerStatusContext();

  const status = ledgerStatus.bufferRestorationStatus(processBufferRestoration);
  const timestamp = processBufferRestoration.effectiveTimestamp;

  const deleteMutation = useDeleteProcessBufferRestoration();
  const openDeleteModal = openDeleteEntityConfirmModal(
    'Feedstock Restoration',
    () => {
      deleteMutation.mutate(
        { processBufferRestorationId: processBufferRestoration.id },
        {
          onError() {
            showNotification({
              title: 'Error Deleting Feedstock Restoration',
              message: 'An error occurred deleting the feedstock restoration.',
              color: 'red',
              icon: <IconX />,
            });
          },
          onSuccess() {
            showNotification({
              title: 'Feedstock Restoration Deleted',
              message:
                'The feedstock restoration has successfully been deleted.',
              color: 'green',
              icon: <IconCheck />,
            });
          },
          onSettled() {
            deleteMutation.reset();
          },
        },
      );
    },
  );

  const renderEventTypeCell = (
    <Group spacing='xs'>
      <ProcessBufferRestorationIcon />
      <Badge color='yellow'>Feedstock Restoration</Badge>
    </Group>
  );

  const sourceCell = (
    <ProcessIdName processId={processBufferRestoration.processId} withIcon />
  );

  const renderDestinationCell = (
    <Group spacing='xs'>
      <ProcessBufferRestorationIcon />
      <Text c='dimmed'>Feedstock Restored</Text>
    </Group>
  );

  const actionsCell = (
    <RowActionsMenu>
      <EditMenuItem
        onClick={() =>
          setCurrentDrawer({
            drawerName: 'Edit Feedstock Restoration',
            id: processBufferRestoration.id,
          })
        }
      />
      <DeleteMenuItem onClick={openDeleteModal} />
    </RowActionsMenu>
  );

  let errorExplanationContent = null;
  if (status.status === 'conflict') {
    errorExplanationContent = (
      <>
        {status.errors.map((error, i) => (
          <ProcessBufferRestorationErrorExplaination
            bufferRestorationError={error}
            key={i}
          />
        ))}
      </>
    );
  }

  return (
    <>
      <InventoryLedgerRowTemplate
        eventType={renderEventTypeCell}
        date={<CalendarDateTime iso8601={timestamp} />}
        entryStatus={status}
        source={sourceCell}
        destination={renderDestinationCell}
        actions={actionsCell}
      />
      {errorExplanationContent}
    </>
  );
}

function ProcessBufferRestorationErrorExplaination({
  bufferRestorationError,
}: {
  bufferRestorationError: BufferRestorationErrorDTO;
}) {
  return match(bufferRestorationError)
    .with({ kind: 'DuplicateBufferRestorationError' }, (error) => (
      <Alert
        color='red'
        title='Process Feedstock Restoration Concurrency Conflict'
      >
        This feedstock restoration event fonclits with another feedstock
        restoration event for the same process:{' '}
        <ProcessIdName processId={error.firstBufferRestoration.processId} />.
      </Alert>
    ))
    .with({ kind: 'NotImplicitlyDepletedBufferRestorationError' }, () => (
      <Alert
        color='red'
        title='Process Feedstock Restoration Depletion-Restoration Conflcit'
      >
        This feedstock restoration event ocurred on a feedstock buffer that was
        not implicitly depleted at time of restoration.
      </Alert>
    ))
    .exhaustive();
}
