import { Loader, Select, SelectProps } from '@mantine/core';
import { ZodType, z } from 'zod';
import { QuantizedWeightDTOSchema } from '../Weights/WeightZodSchemas';
import { useContainerTypes } from '../api/containerType';
import { MaterialContainerId, MaterialContainerTypeDTO } from '../rest-client';

export const ContainerTypeSchema: ZodType<MaterialContainerTypeDTO> = z.object({
  id: z.string().uuid(),
  name: z.string(),
  defaultTareWeight: QuantizedWeightDTOSchema.nullable(),
});

export interface ContainerTypeSelectSpecificProps {
  value: MaterialContainerTypeDTO | null;
  onChange: (containerType: MaterialContainerTypeDTO | null) => void;
}

export type ContainerTypeSelectProps = Omit<
  SelectProps,
  keyof ContainerTypeSelectSpecificProps | 'data'
> &
  ContainerTypeSelectSpecificProps;

export default function ContainerTypeSelect(props: ContainerTypeSelectProps) {
  const {
    value,
    onChange,
    label = 'Container Type',
    placeholder = 'Select type of container',
    nothingFound = 'No container types found',
    clearable = false,
    disabled,
    ...selectProps
  } = props;

  const {
    data: containerTypes,
    isLoading,
    isLoadingError,
    error,
  } = useContainerTypes();

  if (isLoadingError) {
    throw error;
  }

  const selectData =
    containerTypes?.map((ct) => ({
      label: ct.name,
      value: ct.id,
    })) ?? [];

  return (
    <Select
      value={value?.id}
      onChange={(id: MaterialContainerId) => {
        onChange(containerTypes?.find((ct) => ct.id === id) ?? null);
      }}
      data={selectData}
      label={label}
      disabled={disabled || isLoading}
      rightSection={isLoading ? <Loader size='xs' /> : undefined}
      placeholder={isLoading ? 'Loading...' : placeholder}
      nothingFound={nothingFound}
      clearable={clearable}
      {...selectProps}
    />
  );
}
