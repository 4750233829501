import { Alert, Group, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import dayjs, { Dayjs } from 'dayjs';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useFacilityContext } from '../Facility/FacilityContext';
import { SelectedFacilityRequiredDateTimePicker } from '../FacilityDateTimePicker';
import { FormActionButton } from '../Form/FormActionButton';
import { ProcessSelect } from '../Process/ProcessSelect';
import { useAddProcessBufferRestoration } from '../api/processBufferRestoration';
import { ProcessId } from '../rest-client';

export interface AddProcessBufferRestorationFormProps {
  onSuccess: () => void;
  onCancel: () => void;
}

export interface ProcessBufferRestorationFormValues {
  processId: ProcessId | null;
  restoredAt: Dayjs;
}

export function AddProcessBufferRestorationForm(
  props: AddProcessBufferRestorationFormProps,
) {
  const { onSuccess, onCancel } = props;

  const id = useMemo(() => uuidv4(), []);

  const facility = useFacilityContext();
  const form = useForm<ProcessBufferRestorationFormValues>({
    initialValues: {
      processId: null,
      restoredAt: dayjs.utc().tz(facility.timeZoneId),
    },
    validate: {
      processId: (value) => (value ? null : 'Process is required'),
    },
  });

  const addMutation = useAddProcessBufferRestoration();

  if (addMutation.isError) {
    return (
      <Alert
        color='red'
        title='Error Creating Feedstock Restoration'
        withCloseButton
        closeButtonLabel='Clear Error'
        onClose={() => addMutation.reset()}
      >
        The feedstock restoration may or may not have been saved. You can clear
        the error and try again.
      </Alert>
    );
  }

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        if (!values.processId) {
          throw new Error();
        }

        addMutation.mutate(
          {
            id,
            processId: values.processId,
            effectiveTimestamp: values.restoredAt.utc().toISOString(),
          },
          {
            onError() {
              showNotification({
                title: 'Error Creating Feedstock Restoration',
                message: `An error occurred creating the feedstock restoration.`,
                color: 'red',
                icon: <IconX />,
              });
            },
            onSuccess() {
              showNotification({
                title: 'Feedstock Restoration Created',
                message: `The feedstock restoration was successfully created.`,
                color: 'green',
                icon: <IconCheck />,
              });

              onSuccess();
            },
          },
        );
      })}
    >
      <Stack>
        <SelectedFacilityRequiredDateTimePicker
          required
          withAsterisk
          label='Time Feedstock was Restored with Material'
          maxDate={dayjs()}
          {...form.getInputProps('restoredAt')}
        />

        <ProcessSelect
          required
          withAsterisk
          label='Process'
          emptyContent={
            <Alert title='No Processes' color='red'>
              Cannot create a feedstock restoration without a process.
            </Alert>
          }
          {...form.getInputProps('processId')}
        />

        <Group position='right'>
          <FormActionButton
            action='cancel'
            onClick={onCancel}
            loading={addMutation.isLoading}
          >
            Cancel
          </FormActionButton>
          <FormActionButton
            type='submit'
            action='saveCreation'
            loading={addMutation.isLoading}
          >
            Add Feedstock Restoration
          </FormActionButton>
        </Group>
      </Stack>
    </form>
  );
}
