import { useMutation, useQuery } from '@tanstack/react-query';
import {
  OutputContainerChangeCreationDTO,
  OutputContainerChangePatchDTO,
  OutputContainerChangeService,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchOutputContainerChange(
  ctx: QueryFunctionContexts['outputContainerChange']['detail'],
) {
  const [{ outputContainerChangeId }] = ctx.queryKey;
  if (!outputContainerChangeId) {
    throw new Error();
  }
  return await OutputContainerChangeService.getOutputContainerChangeById(
    outputContainerChangeId,
  );
}

export function useOutputContainerChange(
  outputContainerChangeId: string | undefined,
) {
  return useQuery({
    queryKey: queryKeys.outputContainerChange.detail(outputContainerChangeId),
    queryFn: fetchOutputContainerChange,
    enabled: outputContainerChangeId !== undefined,
  });
}

async function createOutputContainerChange(
  outputContainerChange: OutputContainerChangeCreationDTO,
) {
  await OutputContainerChangeService.createOutputContainerChange(
    outputContainerChange,
  );
}

export function useAddOutputContainerChange() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: createOutputContainerChange,
    onSettled() {
      invalidator.invalidateInventoryLedger();
    },
  });
}

async function patchOutputContainerChange({
  outputContainerChangeId,
  patch,
}: {
  outputContainerChangeId: string;
  patch: OutputContainerChangePatchDTO;
}) {
  await OutputContainerChangeService.patchOutputContainerChange(
    outputContainerChangeId,
    patch,
  );
}

export function usePatchOutputContainerChange() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: patchOutputContainerChange,
    onSettled(data, error, { outputContainerChangeId }) {
      invalidator.invalidateKeys(
        queryKeys.outputContainerChange.detail(outputContainerChangeId),
      );
      invalidator.invalidateInventoryLedger();
    },
  });
}

async function deleteOutputContainerChange({
  outputContainerChangeId,
}: {
  outputContainerChangeId: string;
}) {
  await OutputContainerChangeService.deleteOutputContainerChange(
    outputContainerChangeId,
  );
}

export function useDeleteOutputContainerChange() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: deleteOutputContainerChange,
    onSettled(data, error, { outputContainerChangeId }) {
      invalidator.invalidateKeys(
        queryKeys.outputContainerChange.detail(outputContainerChangeId),
      );
      invalidator.invalidateInventoryLedger();
    },
  });
}
