import { Alert, Checkbox, Group, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ContainerSelect from '../Container/ContainerSelect.tsx';
import { useFacilityContext } from '../Facility/FacilityContext';
import { SelectedFacilityRequiredDateTimePicker } from '../FacilityDateTimePicker.tsx';
import { FormActionButton } from '../Form/FormActionButton';
import { ProcessSelect } from '../Process/ProcessSelect';
import { ProcessOutputPortSelect } from '../ProcessOutputPort/ProcessOutputPortSelect';
import { useAddOutputContainerChange } from '../api/outputContainerChange';
import { useProcess } from '../api/process';
import { MaterialContainerId, OutputPortId, ProcessId } from '../rest-client';

export interface AddOutputContainerChangeFormProps {
  onSuccess: () => void;
  onCancel: () => void;
}

export interface OutputContainerChangeFormValues {
  processId: ProcessId | null;
  outputPortId: OutputPortId | null;
  materialContainerId: MaterialContainerId | null;
  previousContainerFilled: boolean;
  changeTime: Dayjs;
}

export function AddOutputContainerChangeForm(
  props: AddOutputContainerChangeFormProps,
) {
  const { onSuccess, onCancel } = props;

  const id = useMemo(() => uuidv4(), []);

  const facility = useFacilityContext();
  const form = useForm<OutputContainerChangeFormValues>({
    initialValues: {
      processId: null,
      outputPortId: null,
      materialContainerId: null,
      previousContainerFilled: false,
      changeTime: dayjs.utc().tz(facility.timeZoneId),
    },
    validate: {
      processId: (value) => (value ? null : 'Process is required'),
      outputPortId: (value) => (value ? null : 'Output Port is required'),
    },
  });

  const { data: process } = useProcess(form.values.processId ?? undefined);
  useEffect(() => {
    form.setFieldValue(
      'outputPortId',
      process?.outputs[0].outputPortId ?? null,
    );
  }, [form, process]);

  const addMutation = useAddOutputContainerChange();

  if (addMutation.isError) {
    return (
      <Alert
        color='red'
        title='Error Creating Output Container Change'
        withCloseButton
        closeButtonLabel='Clear Error'
        onClose={() => addMutation.reset()}
      >
        The output container change may or may not have been saved. You can
        clear the error and try again.
      </Alert>
    );
  }

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        if (values.processId === null || values.outputPortId === null) {
          throw new Error();
        }

        addMutation.mutate(
          {
            id,
            processId: values.processId,
            outputPortId: values.outputPortId,
            containerId: values.materialContainerId,
            previousContainerFilled: values.previousContainerFilled,
            effectiveTimestamp: values.changeTime.utc().toISOString(),
          },
          {
            onError() {
              showNotification({
                title: 'Error Creating Output Container Change',
                message: `An error occurred creating the output container change.`,
                color: 'red',
                icon: <IconX />,
              });
            },
            onSuccess() {
              showNotification({
                title: 'Output Container Change Created',
                message: `The output container change was successfully created.`,
                color: 'green',
                icon: <IconCheck />,
              });

              onSuccess();
            },
          },
        );
      })}
    >
      <Stack>
        <SelectedFacilityRequiredDateTimePicker
          required
          withAsterisk
          label='Change Time'
          maxDate={dayjs()}
          {...form.getInputProps('changeTime')}
        />

        <ProcessSelect
          required
          withAsterisk
          label='Process'
          emptyContent={
            <Alert title='No Processes' color='red'>
              Cannot create a output container change without a process.
            </Alert>
          }
          {...form.getInputProps('processId')}
        />

        {process && (
          <ProcessOutputPortSelect
            label='Output Port'
            processOutputPorts={process.outputs}
            {...form.getInputProps('outputPortId')}
          />
        )}

        <ContainerSelect
          label='New Output Container'
          facilityId={facility.id}
          timestamp={form.values.changeTime}
          hideEmpty={false}
          hideFull={false}
          clearable
          {...form.getInputProps('materialContainerId')}
        />

        <Checkbox
          label='Previous Output Container Filled'
          size='md'
          checked={form.values.previousContainerFilled}
          onChange={(event) => {
            form.setFieldValue(
              'previousContainerFilled',
              event.currentTarget.checked,
            );
          }}
        />

        <Group position='right'>
          <FormActionButton
            action='cancel'
            onClick={onCancel}
            loading={addMutation.isLoading}
          >
            Cancel
          </FormActionButton>
          <FormActionButton
            type='submit'
            action='saveCreation'
            loading={addMutation.isLoading}
          >
            Add Output Container Change
          </FormActionButton>
        </Group>
      </Stack>
    </form>
  );
}
