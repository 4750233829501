import { ZodType, z } from 'zod';
import { QuantizedWeightDTO, WeightUnit } from '../rest-client';

// tickSize and humanReadableCentralValue are strings, not numbers, because decimal types cannot be faithfully represented in JSON
// see QuantizedWeightDTO.cs for more context
export const QuantizedWeightDTOSchema: ZodType<QuantizedWeightDTO> = z
  .object({
    unit: z.nativeEnum(WeightUnit),
    tickSize: z.string(),
    ticks: z.number().nonnegative(),
    humanReadableCentralValue: z.string(),
  })
  .strict(); // will throw an error if there are any additional keys

export const OptionalWeightSchema = z
  .object({
    enabled: z.boolean(),
    unit: z.nativeEnum(WeightUnit),
    tickSize: z
      .number()
      .positive({ message: 'Weight tick size must be positive' }),
    ticks: z
      .number()
      .positive({ message: 'Weight must be positive' })
      .nullable(),
  })
  .superRefine((weight, ctx) => {
    if (weight.enabled) {
      if (weight.ticks === null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['ticks'],
          message: 'Weight is required',
        });
      } else if (weight.ticks < weight.tickSize) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['ticks'],
          message: 'Weight must be postive', // TODO(2316): not the most logically representative message; change once tick size is variable
        });
      }
    }
  });
