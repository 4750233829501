import {
  Alert,
  Anchor,
  Breadcrumbs,
  Button,
  Container,
  Flex,
  Paper,
  PaperProps,
  Skeleton,
  Stack,
  Title,
} from '@mantine/core';
import { useLinkProps } from '@swan-io/chicane';
import { IconAlertCircle } from '@tabler/icons-react';
import { ReactNode } from 'react';
import { P, match } from 'ts-pattern';
import { ErrorBoundary, FallbackProps } from '../ErrorBoundary';
import cssClasses from './AppPage.module.css';

export interface TitleRoute {
  routeName: string;
  title: ReactNode | null;
}

function BreadcrumbLink(props: { titleRoute: TitleRoute }) {
  const { titleRoute } = props;
  const { onClick } = useLinkProps({
    href: titleRoute.routeName,
    replace: false,
  });
  return (
    <Anchor
      href={titleRoute.routeName}
      className={cssClasses.breadcrumbAnchor}
      onClick={onClick}
    >
      <Skeleton visible={titleRoute.title === null} width='unset'>
        {titleRoute.title ?? 'Loading...'}
      </Skeleton>
    </Anchor>
  );
}

function AppPageErrorFallback({ resetError }: FallbackProps) {
  // TODO(2300): Display error information if possible
  return (
    <Alert color='red' title='Error' icon={<IconAlertCircle size={16} />}>
      <Stack>
        Something went wrong displaying this data.
        <Button onClick={resetError}>Try Again</Button>
      </Stack>
    </Alert>
  );
}

function AppPageSectionFallback({ resetError }: FallbackProps) {
  // TODO(2300): Display error information if possible
  return (
    <Alert color='red' title='Error' icon={<IconAlertCircle size={16} />}>
      <Stack>
        Something went wrong displaying this data.
        <Button onClick={resetError}>Try Again</Button>
      </Stack>
    </Alert>
  );
}

export interface AppPageProps {
  breadcrumbs?: (TitleRoute | string)[];
  title?: ReactNode | null;
  titleRight?: ReactNode;
  children: ReactNode;
}

export const AppPage = (props: AppPageProps) => {
  const { breadcrumbs = [], title, titleRight, children } = props;
  const linkItems = breadcrumbs.map((breadcrumb, idx) =>
    match(breadcrumb)
      .with(P.string, (bc) => <Title order={1}>{bc}</Title>)
      .otherwise((bc) => <BreadcrumbLink titleRoute={bc} key={idx} />),
  );
  const lastItem = (
    <Skeleton visible={title === null} width='unset'>
      <Title>{title ?? 'Loading...'}</Title>
    </Skeleton>
  );
  return (
    <Container size='xl' h='100%' className={cssClasses.appContentContainer}>
      <Stack h='100%'>
        <Flex gap='lg' align='end'>
          <Breadcrumbs separator={<Title color='dimmed'> / </Title>}>
            {linkItems}
            {title !== undefined && lastItem}
          </Breadcrumbs>
          {titleRight}
        </Flex>

        <ErrorBoundary fallback={AppPageErrorFallback}>
          {children}
        </ErrorBoundary>
      </Stack>
    </Container>
  );
};

type AppPageSectionProps = Omit<PaperProps, 'p' | 'shadow'>;

const AppPageSection = (props: AppPageSectionProps) => {
  const { children, ...otherProps } = props;

  return (
    <Paper p='md' shadow='md' {...otherProps}>
      <ErrorBoundary fallback={AppPageSectionFallback}>
        {children}
      </ErrorBoundary>
    </Paper>
  );
};

AppPage.Section = AppPageSection;
