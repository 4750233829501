import {
  Button,
  Center,
  FocusTrap,
  Group,
  Loader,
  NumberInput,
  Paper,
  Progress,
  RingProgress,
  Stack,
  Table,
  Text,
  Title,
} from '@mantine/core';
import { IconRefresh } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { match } from 'ts-pattern';
import { AppPage } from '../App/AppPage';
import { MaterialClassSetName } from '../MaterialClassSet/MaterialClassSetName';
import { BinaryConfusionMatrixStats } from '../RecoveryGoal/BinaryConfusionMatrixStats';
import {
  RecoveryGoalExcludedIcon,
  RecoveryGoalIncludedIcon,
} from '../RecoveryGoal/RecoveryGoalIcons';
import { RecoveryGoalTree } from '../RecoveryGoal/RecoveryGoalTree';
import { RecoveryStrategyName } from '../RecoveryStrategy/RecoveryStategyName';
import { usePatchMaterialClassSetComposition } from '../api/materialClassSetComposition';
import { useRecoveryGoals } from '../api/recoveryGoal';
import {
  usePatchRecoveryStrategySimulation,
  useRecoveryStrategySimulation,
} from '../api/recoveryStrategySimulation';
import { LabeledValue } from '../common';
import { EChart } from '../echarts/BareEChart';
import { useCategoricalColors } from '../lib/colors';
import { formatPercentage } from '../lib/percentages';
import { RecoveryGoalProbTreeDTO } from '../rest-client';
import { Router } from '../router';
import { DeleteRecoveryStrategySimulationButton } from './DeleteRecoveryStrategySimulationButton';
import { MaterialClassLegendItem } from './MaterialClassLegendItem';
import {
  RecoveryStrategySimulationCtxProvider,
  useRecoveryStrategySimulationCtx,
} from './RecoveryStrategySimulationContext';
import { RecoveryStrategySimulationDiagram } from './RecoveryStrategySimulationDiagram';

export default function RecoveryStrategySimulationDetailPage(props: {
  simulationId: string;
}) {
  const { simulationId } = props;

  const { data: simulation } = useRecoveryStrategySimulation(simulationId);

  const [selectedMaterialClassId, setSelectedMaterialClassId] = useState<
    string | null
  >(null);
  const [selectedRecoveryGoalProbTree, setSelectedRecoveryGoalProbTree] =
    useState<RecoveryGoalProbTreeDTO | null>(null);
  const [selectedOutput, setSelectedOutput] = useState<
    'positive' | 'negative' | null
  >(null);
  const [feedTotal, setFeedTotal] = useState<number | null>(null);

  if (!simulation) {
    return (
      <Center>
        <Loader variant='bars' size='xl' />
      </Center>
    );
  }

  return (
    <AppPage
      breadcrumbs={[
        {
          title: 'Recovery Strategies',
          routeName: Router.RecoveryStrategyList(),
        },
        {
          title: simulation.recoveryStrategy.name,
          routeName: Router.RecoveryStrategyDetail({
            recoveryStrategyId: simulation.recoveryStrategy.id,
          }),
        },
        'Simulations',
        simulation.name,
      ]}
      titleRight={
        <DeleteRecoveryStrategySimulationButton simulationId={simulationId} />
      }
    >
      <RecoveryStrategySimulationCtxProvider
        simulation={simulation}
        selectedMaterialClassId={selectedMaterialClassId}
        setSelectedMaterialClassId={setSelectedMaterialClassId}
        selectedRecoveryGoalProbTree={selectedRecoveryGoalProbTree}
        setSelectedRecoveryGoalProbTree={setSelectedRecoveryGoalProbTree}
        selectedOutput={selectedOutput}
        setSelectedOutput={setSelectedOutput}
        feedTotal={feedTotal}
        setFeedTotal={setFeedTotal}
      >
        <RecoveryStrategySimulationDetailsSection />
        <AppPage.Section>
          <Title order={3}>Simulation Parameters</Title>
          <SimulationParametersInput />
          <NumberInput
            size='md'
            mt='lg'
            w='fit-content'
            label='Feed Total'
            value={feedTotal ?? ''}
            onChange={(v) => setFeedTotal(v === '' ? null : v)}
          />
        </AppPage.Section>
        <Stack spacing='xs'>
          <Title order={2} ta='center'>
            Mass Flow
          </Title>
          <RecoveryStrategySimulationDiagram />
        </Stack>
        <Stack>
          <SelectedRecoveryGoalMetrics />
          <SelectedOutputComposition />
        </Stack>
      </RecoveryStrategySimulationCtxProvider>
    </AppPage>
  );
}

function RecoveryStrategySimulationDetailsSection() {
  const { simulation } = useRecoveryStrategySimulationCtx();

  return (
    <AppPage.Section>
      <Stack>
        <Stack spacing='xs'>
          <Title order={3}>Simulation Details</Title>
          <Group>
            <LabeledValue label='Name'>{simulation.name}</LabeledValue>
            <LabeledValue label='Created at'>
              {dayjs.utc(simulation.insertedAt).format('LLL')}
            </LabeledValue>
            <LabeledValue label='Recovery Strategy'>
              <RecoveryStrategyName
                recoveryStrategy={simulation.recoveryStrategy}
              />
            </LabeledValue>
            <LabeledValue label='Sample Analysis Material Class Set'>
              <MaterialClassSetName
                materialClassSet={simulation.materialClassSet}
              />
            </LabeledValue>
          </Group>
        </Stack>
        <Stack spacing='xs'>
          <Title order={4}>Recovery Strategy Behavior Tree</Title>
          <Paper withBorder p='md'>
            <RecoveryGoalTree tree={simulation.recoveryGoalBehaviorTree} />
          </Paper>
        </Stack>
      </Stack>
    </AppPage.Section>
  );
}

function SimulationParametersInput() {
  const { simulation } = useRecoveryStrategySimulationCtx();
  const colors = useCategoricalColors();

  const rgQuery = useRecoveryGoals();
  const recoveryGoals =
    rgQuery.data && new Map(rgQuery.data.map((rg) => [rg.id, rg]));

  const recoveryGoalIdSet = new Set<string>();
  simulation.recoveryStrategy.paths
    .flatMap((p) => p.steps.map((s) => s.recoveryGoalId))
    .forEach((recoveryGoalId) => {
      recoveryGoalIdSet.add(recoveryGoalId);
    });

  const recoveryGoalIds = [...recoveryGoalIdSet];

  const inputCompositionTotal = Object.values(
    simulation.inputComposition.materialClassesProportions,
  ).reduce((t, v) => t + v, 0);

  // TODO(2339): highlight row and column with color?

  return (
    <Table>
      <thead>
        <tr>
          <th>Recovery Goal Accuracy</th>
          <th>Material Class</th>
          <th>Composition</th>

          {recoveryGoalIds.map((rgId) => (
            <th
              key={rgId}
              onClick={() => {
                // TODO(2339): Change the recovery goal
              }}
            >
              {recoveryGoals?.get(rgId)?.name ?? ''}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {simulation.materialClassSet.materialClasses.map((materialClass, i) => {
          const total = Object.values(
            simulation.inputComposition.materialClassesProportions,
          ).reduce((t, v) => t + v, 0);

          const proportion =
            simulation.inputComposition.materialClassesProportions[
              materialClass.id
            ];

          return (
            <tr key={materialClass.id}>
              <td>
                <MaterialClassLegendItem
                  materialClass={materialClass}
                  color={colors[i]}
                />
              </td>

              <td>
                <InputCompositionInput
                  key={materialClass.id}
                  materialClassId={materialClass.id}
                />
              </td>

              <td>
                <Progress
                  h='100%'
                  radius='xs'
                  color={colors[i]}
                  value={(100 * proportion) / inputCompositionTotal}
                />
                {proportion >= 0
                  ? (100 * (proportion / total)).toFixed(2) + '%'
                  : '?'}
              </td>

              {recoveryGoalIds.map((rgId) => {
                const rg = recoveryGoals?.get(rgId);
                const rgClasses = rg
                  ? new Set(rg.materialClasses.map((mc) => mc.id))
                  : undefined;
                const classIncluded = rgClasses?.has(materialClass.id);
                return (
                  <td
                    key={`${rgId}-${materialClass.id}`}
                    style={{ textAlign: 'right' }}
                  >
                    <Group position='center' noWrap spacing={0}>
                      {match(classIncluded)
                        .with(undefined, () => <Loader size='xs' />)
                        .with(true, () => (
                          <RecoveryGoalIncludedIcon
                            size='1.4em'
                            color='green'
                          />
                        ))
                        .with(false, () => (
                          <RecoveryGoalExcludedIcon
                            size='1.4em'
                            color='orange'
                          />
                        ))
                        .exhaustive()}
                      <RingProgress
                        size={25}
                        thickness={5}
                        sections={[
                          {
                            value:
                              simulation.recoveryGoalAccuracies[rgId][
                                materialClass.id
                              ] * 100,
                            color: 'teal',
                          },
                          {
                            value:
                              100 -
                              simulation.recoveryGoalAccuracies[rgId][
                                materialClass.id
                              ] *
                                100,
                            color: 'red',
                          },
                        ]}
                      />
                      <RecoveryGoalAccuracyInput
                        recoveryGoalId={rgId}
                        materialClassId={materialClass.id}
                      />
                    </Group>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

function InputCompositionInput(props: { materialClassId: string }) {
  const { materialClassId } = props;
  const { simulation } = useRecoveryStrategySimulationCtx();

  const proportions = new Map(
    Object.entries(simulation.inputComposition.materialClassesProportions),
  );

  const currentValue = proportions.get(materialClassId);

  const [editing, setEditing] = useState(currentValue === undefined);
  const patchMutation = usePatchMaterialClassSetComposition();

  const [value, setValue] = useState(currentValue);

  const mutate = useCallback(() => {
    if (value === undefined) return;
    return patchMutation.mutate(
      {
        id: simulation.inputComposition.id,
        patch: { materialClassesProportions: { [materialClassId]: value } },
      },
      {
        onSuccess() {
          setEditing(false);
        },
      },
    );
  }, [
    patchMutation,
    simulation.inputComposition.id,
    value,
    materialClassId,
    setEditing,
  ]);

  if (patchMutation.isError) {
    return (
      <Button
        color='red'
        onClick={() => {
          setEditing(false);
          patchMutation.reset();
          setValue(currentValue);
        }}
      >
        <IconRefresh />
      </Button>
    );
  }

  return editing ? (
    <FocusTrap>
      <NumberInput
        data-autofocus
        hideControls
        disabled={patchMutation.isLoading}
        rightSection={patchMutation.isLoading ? <Loader size='xs' /> : null}
        w='8ch'
        value={value ?? ''}
        onChange={(v) => {
          if (v === '') {
            setValue(undefined);
          } else {
            setValue(v);
          }
        }}
        onBlur={() => mutate()}
        onKeyDown={(e) => {
          if (e.code === 'Enter') {
            mutate();
          }
        }}
      />
    </FocusTrap>
  ) : (
    <Text ta='right' onClick={() => setEditing(true)}>
      {value ?? '-'}
    </Text>
  );
}

function RecoveryGoalAccuracyInput(props: {
  recoveryGoalId: string;
  materialClassId: string;
}) {
  const { recoveryGoalId, materialClassId } = props;
  const { simulation } = useRecoveryStrategySimulationCtx();

  const [editing, setEditing] = useState(false);
  const patchMutation = usePatchRecoveryStrategySimulation();

  const currentAccuracy =
    simulation.recoveryGoalAccuracies[recoveryGoalId][materialClassId];

  const [accuracy, setAccuracy] = useState(currentAccuracy);

  const mutate = useCallback(() => {
    patchMutation.mutate(
      {
        simulationId: simulation.id,
        patch: {
          recoveryGoalAccuracies: {
            [recoveryGoalId]: { [materialClassId]: accuracy },
          },
        },
      },
      {
        onSuccess() {
          setEditing(false);
        },
      },
    );
  }, [
    patchMutation,
    simulation.id,
    recoveryGoalId,
    materialClassId,
    accuracy,
    setEditing,
  ]);

  if (patchMutation.isError) {
    return (
      <Button
        color='red'
        onClick={() => {
          setEditing(false);
          patchMutation.reset();
          setAccuracy(currentAccuracy);
        }}
      >
        <IconRefresh />
      </Button>
    );
  }

  return editing ? (
    <NumberInput
      disabled={patchMutation.isLoading}
      rightSection={patchMutation.isLoading ? <Loader size='xs' /> : null}
      ref={(e) => e?.focus()}
      w='8ch'
      step={0.01}
      precision={2}
      hideControls
      value={accuracy * 100}
      onKeyDown={(e) => {
        if (e.code === 'Enter') {
          mutate();
        }
      }}
      onChange={(v) => {
        if (v === '') {
          setAccuracy(0);
        } else {
          setAccuracy(v / 100);
        }
      }}
      onBlur={() => mutate()}
    />
  ) : (
    <Text weight={500} onClick={() => setEditing(true)}>
      {(accuracy * 100).toFixed(2)}%
    </Text>
  );
}

function SelectedOutputComposition() {
  const {
    simulation,
    selectedRecoveryGoalProbTree,
    selectedOutput,
    feedTotal,
  } = useRecoveryStrategySimulationCtx();

  const colors = useCategoricalColors();

  const valueFormatter = useCallback(
    (val: number | null) => (val === null ? '?' : val.toFixed(2)),
    [],
  );

  if (selectedRecoveryGoalProbTree === null) {
    return null;
  }

  if (simulation.recoveryGoalProbTree === null) {
    return null;
  }

  if (selectedOutput === null) {
    return null;
  }

  const { recoveryGoal } = selectedRecoveryGoalProbTree;

  const outputProb = selectedRecoveryGoalProbTree[`${selectedOutput}Prob`];

  const outputTotalProb = Object.values(outputProb).reduce((a, b) => a + b, 0);

  return (
    <AppPage.Section>
      <Title order={3}>
        {recoveryGoal.name} {selectedOutput} output
      </Title>
      {feedTotal ? (
        <LabeledValue label='Output Total'>
          {(feedTotal * outputTotalProb).toFixed(1)}
        </LabeledValue>
      ) : null}

      <EChart
        h={500}
        w='100%'
        option={{
          grid: { containLabel: true, top: 0, bottom: 0 },
          tooltip: {
            valueFormatter: feedTotal ? valueFormatter : formatPercentage,
          },
          yAxis: {
            type: 'category',
            data: simulation.materialClassSet.materialClasses
              .map((mc) => mc.name)
              .reverse(),
          },
          xAxis: {
            type: 'value',
            axisLabel: {
              formatter: feedTotal ? undefined : formatPercentage,
            },
          },
          series: [
            {
              type: 'bar',
              data: simulation.materialClassSet.materialClasses
                .map((mc, i) => ({
                  value: feedTotal
                    ? feedTotal * outputProb[mc.id]
                    : outputProb[mc.id] / outputTotalProb,
                  itemStyle: {
                    color: colors[i],
                  },
                }))
                .reverse(),
            },
          ],
        }}
      />
    </AppPage.Section>
  );
}

function SelectedRecoveryGoalMetrics() {
  const { simulation, selectedRecoveryGoalProbTree } =
    useRecoveryStrategySimulationCtx();

  if (selectedRecoveryGoalProbTree === null) {
    return null;
  }

  if (simulation.recoveryGoalProbTree === null) {
    return null;
  }

  const { recoveryGoal } = selectedRecoveryGoalProbTree;

  return (
    <>
      <Title order={3}>{recoveryGoal.name} Recovery Performance</Title>
      <Group position='apart'>
        <BinaryConfusionMatrixStats
          matrix={selectedRecoveryGoalProbTree.binaryConfusionMatrix}
        />
      </Group>
    </>
  );
}
