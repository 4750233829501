import {
  Input,
  SegmentedControl,
  SegmentedControlProps,
  Stack,
} from '@mantine/core';
import { useEffect, useMemo } from 'react';
import { ProcessOutputPortDTO } from '../rest-client';
import cssClasses from './ProcessOutputPortSelect.module.css';

export interface ProcessOutputPortSelectBaseProps {
  processOutputPorts?: ProcessOutputPortDTO[];
  value: string | null;
  onChange: (outputPortId: string | null) => void;
  label: string;
}

export type ProcessOutputPortSelectProps = Partial<
  Omit<SegmentedControlProps, keyof ProcessOutputPortSelectBaseProps>
> &
  ProcessOutputPortSelectBaseProps;

export function ProcessOutputPortSelect(props: ProcessOutputPortSelectProps) {
  const {
    processOutputPorts = [],
    value,
    onChange,
    label,
    ...restProps
  } = props;

  const data = useMemo(() => {
    return processOutputPorts.map(({ name, outputPortId }) => ({
      label: name,
      value: outputPortId,
    }));
  }, [processOutputPorts]);

  // Reset value to null if possible values changes to no longer include value
  useEffect(() => {
    if (value === null) {
      return;
    }

    const validIds = processOutputPorts.map(({ outputPortId }) => outputPortId);

    if (!validIds.includes(value)) {
      onChange(null);
    }
  }, [processOutputPorts, value, onChange]);

  return (
    <Stack spacing={4}>
      <Input.Label required>{label}</Input.Label>
      <SegmentedControl
        className={cssClasses.outputPortSelect}
        value={value ?? undefined}
        data={data}
        disabled={processOutputPorts.length === 0}
        {...restProps}
      />
    </Stack>
  );
}
