import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DrawerForm, DrawerFormDrawerProps } from '../Form/DrawerForm';
import { useCreateInternalSinkContainerTransfer } from '../api/internalSinkContainerTransfer';
import { InternalSinkContainerTransferCreationDTO } from '../rest-client';
import { Router } from '../router';
import {
  InternalSinkContainerTransferFormFields,
  InternalSinkContainerTransferFormProps,
  InternalSinkContainerTransferFormValues,
  useInternalSinkContainerTransferForm,
} from './InternalSinkContainerTransferForm';

export type AddInternalSinkContainerTransferDrawerFormProps =
  InternalSinkContainerTransferFormProps & {
    onSuccess?: (
      sinkTransfer: InternalSinkContainerTransferCreationDTO,
    ) => void;
  } & DrawerFormDrawerProps;

export function AddInternalSinkContainerTransferDrawerForm(
  props: AddInternalSinkContainerTransferDrawerFormProps,
) {
  const {
    internalMaterialSinkId,
    containerId,
    onSuccess = (sinkTransfer: InternalSinkContainerTransferCreationDTO) => {
      Router.replace('InternalSinkTransferDetail', {
        sinkTransferId: sinkTransfer.id,
      });
    },
    onClose,
    ...drawerFormDrawerProps
  } = props;

  const createMutation = useCreateInternalSinkContainerTransfer();
  const form = useInternalSinkContainerTransferForm({
    internalMaterialSinkId,
    containerId,
  });
  const sinkTransferId = useMemo(() => uuidv4(), []);

  const formValuesToDto = ({
    timestamp,
    containerId,
    containerEmptied,
    internalMaterialSinkId,
    netRemovedWeight,
    name,
  }: InternalSinkContainerTransferFormValues) => {
    if (containerId === null || internalMaterialSinkId === null) {
      throw new Error('Source container and export destination cannot be null');
    }

    const sinkTransfer: InternalSinkContainerTransferCreationDTO = {
      id: sinkTransferId,
      containerId,
      sourceEmptied: containerEmptied,
      transferredAt: timestamp.utc().toISOString(),
      internalSinkId: internalMaterialSinkId,
      netWeightRemoved: null,
      name: name.length === 0 ? null : name,
    };

    if (netRemovedWeight.enabled) {
      if (
        netRemovedWeight.tickSize === null ||
        netRemovedWeight.ticks === null ||
        netRemovedWeight.unit === null
      ) {
        throw new Error(
          'Net weight removed cannot be null if specify net weight removed option is enabled',
        );
      }
      sinkTransfer.netWeightRemoved = {
        unit: netRemovedWeight.unit,
        tickSize: `${netRemovedWeight.tickSize}`,
        ticks: netRemovedWeight.ticks,
      };
    }

    return sinkTransfer;
  };

  return (
    <DrawerForm
      entityName='Material Export'
      form={form}
      drawerFormVariant='create'
      mutation={createMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      onClose={onClose}
      {...drawerFormDrawerProps}
    >
      <InternalSinkContainerTransferFormFields
        form={form}
        sourceContainerSelectVariant='material'
      />
    </DrawerForm>
  );
}
