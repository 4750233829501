import { Button, ButtonProps } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { CommodityIcon } from '../Icons';
import {
  AddCommodityDrawerForm,
  AddComomdityDrawerFormProps,
} from './AddCommodityDrawerForm';

export type AddCommodityButtonProps = Omit<ButtonProps, 'onClick'> & {
  addCommodityDrawerFormProps: Omit<
    AddComomdityDrawerFormProps,
    'onClose' | 'opened'
  >;
};

export default function AddCommodityButton(props: AddCommodityButtonProps) {
  const {
    addCommodityDrawerFormProps,
    leftIcon = <CommodityIcon />,
    children = 'Add Commodity',
    ...buttonProps
  } = props;

  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  return (
    <>
      <Button onClick={openDrawer} leftIcon={leftIcon} {...buttonProps}>
        {children}
      </Button>
      <AddCommodityDrawerForm
        opened={drawerOpened}
        onClose={closeDrawer}
        {...addCommodityDrawerFormProps}
      />
    </>
  );
}
