import { Alert, Checkbox, Group, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import dayjs from 'dayjs';
import ContainerSelect from '../Container/ContainerSelect';
import { useFacilityContext } from '../Facility/FacilityContext';
import { SelectedFacilityRequiredDateTimePicker } from '../FacilityDateTimePicker';
import { FormActionButton } from '../Form/FormActionButton';
import { ProcessSelect } from '../Process/ProcessSelect';
import {
  usePatchProcessBufferTransfer,
  useProcessBufferTransfer,
} from '../api/processBufferTransfer';
import { useSetFormInitialValuesFromQuery } from '../lib/useSetFormInitialValuesFromQuery';
import { ProcessBufferTransferPatchDTO } from '../rest-client';
import { ProcessBufferTransferFormValues } from './AddProcessBufferTransferForm';

export interface EditProcessBufferTransferFormProps {
  modelId: string;
  onSuccess: () => void;
  onCancel: () => void;
}

export function EditProcessBufferTransferForm(
  props: EditProcessBufferTransferFormProps,
) {
  const { modelId, onSuccess, onCancel } = props;

  const {
    data: processBufferTransfer,
    isLoadingError,
    error,
  } = useProcessBufferTransfer(modelId);
  const patchMutation = usePatchProcessBufferTransfer();
  const facility = useFacilityContext();
  const form = useForm<ProcessBufferTransferFormValues>({
    initialValues: {
      processId: null,
      effectivelyTransferred: dayjs.utc().tz(facility.timeZoneId),
      materialContainerId: null,
      sourceEmptied: false,
    },
  });

  useSetFormInitialValuesFromQuery(
    form,
    processBufferTransfer && {
      materialContainerId: processBufferTransfer.containerId,
      processId: processBufferTransfer.processId,
      sourceEmptied: processBufferTransfer.sourceEmptied,
      effectivelyTransferred: dayjs
        .utc(processBufferTransfer.effectiveTimestamp)
        .tz(facility.timeZoneId),
    },
  );

  if (isLoadingError) {
    throw error;
  }

  if (patchMutation.isError) {
    return (
      <Alert
        color='red'
        title='Error Updating Feedstock Transfer'
        withCloseButton
        onClose={() => patchMutation.reset()}
      >
        Your changes to the feedstock transfer may or may not have been saved.
        You can clear the error and try again.
      </Alert>
    );
  }

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        if (values.materialContainerId === null || values.processId === null) {
          throw new Error();
        }

        const patch: ProcessBufferTransferPatchDTO = {
          ...(form.isDirty('materialContainerId') && {
            materialContainerId: values.materialContainerId,
          }),
          ...(form.isDirty('sourceEmptied') && {
            sourceEmptied: values.sourceEmptied,
          }),
          ...(form.isDirty('effectivelyTransferred') && {
            effectivelyTransferred: values.effectivelyTransferred
              .utc()
              .toISOString(),
          }),
        };

        patchMutation.mutate(
          { processBufferTransferId: modelId, patch },
          {
            onError() {
              showNotification({
                title: 'Error Updating Feedstock Transfer',
                message: `An error occurred updating the feedstock transfer.`,
                color: 'red',
                icon: <IconX />,
              });
            },
            onSuccess() {
              onSuccess();
              showNotification({
                title: 'Feedstock Transfer Updated',
                message: 'The feedstock transfer was successfully updated.',
                color: 'green',
                icon: <IconCheck />,
              });
            },
          },
        );
      })}
    >
      <Stack>
        <SelectedFacilityRequiredDateTimePicker
          required
          withAsterisk
          label='Effectively Transferred'
          maxDate={dayjs()}
          {...form.getInputProps('effectivelyTransferred')}
        />

        <ContainerSelect
          required
          withAsterisk
          label='Source Container'
          timestamp={form.values.effectivelyTransferred}
          facilityId={facility.id}
          hideEmpty={false}
          hideFull={false}
          clearable
          {...form.getInputProps('materialContainerId')}
        />

        <Checkbox
          label='Source Container Emptied'
          size='md'
          checked={form.values.sourceEmptied}
          onChange={(event) => {
            form.setFieldValue('sourceEmptied', event.currentTarget.checked);
          }}
        />

        <ProcessSelect
          disabled
          label='Process'
          emptyContent={
            <Alert title='No Processes' color='red'>
              Cannot create a feedstock transfer without a process.
            </Alert>
          }
          {...form.getInputProps('processId')}
        />

        <Group position='right' mt='md'>
          <FormActionButton
            action='cancel'
            onClick={onCancel}
            loading={patchMutation.isLoading}
          >
            Cancel
          </FormActionButton>
          <FormActionButton
            type='submit'
            action='saveEdits'
            loading={patchMutation.isLoading}
          >
            Save Feedstock Transfer
          </FormActionButton>
        </Group>
      </Stack>
    </form>
  );
}
