import { Alert, Checkbox, Group, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import dayjs from 'dayjs';
import ContainerSelect from '../Container/ContainerSelect';
import { useFacilityContext } from '../Facility/FacilityContext';
import { SelectedFacilityRequiredDateTimePicker } from '../FacilityDateTimePicker.tsx';
import { FormActionButton } from '../Form/FormActionButton';
import { ProcessSelect } from '../Process/ProcessSelect';
import { ProcessOutputPortSelect } from '../ProcessOutputPort/ProcessOutputPortSelect.tsx';
import {
  useOutputContainerChange,
  usePatchOutputContainerChange,
} from '../api/outputContainerChange';
import { useProcess } from '../api/process.ts';
import { useSetFormInitialValuesFromQuery } from '../lib/useSetFormInitialValuesFromQuery';
import { OutputContainerChangePatchDTO } from '../rest-client';
import { OutputContainerChangeFormValues } from './AddOutputContainerChangeForm';

export interface EditOutputContainerChangeFormProps {
  modelId: string;
  onSuccess: () => void;
  onCancel: () => void;
}

export function EditOutputContainerChangeForm(
  props: EditOutputContainerChangeFormProps,
) {
  const { modelId, onSuccess, onCancel } = props;

  const {
    data: outputContainerChange,
    isLoadingError,
    error,
  } = useOutputContainerChange(modelId);
  const patchMutation = usePatchOutputContainerChange();
  const facility = useFacilityContext();
  const form = useForm<OutputContainerChangeFormValues>({
    initialValues: {
      processId: null,
      outputPortId: null,
      materialContainerId: null,
      previousContainerFilled: false,
      changeTime: dayjs.utc().tz(facility.timeZoneId),
    },
  });

  useSetFormInitialValuesFromQuery(
    form,
    outputContainerChange && {
      processId: outputContainerChange.process.id,
      outputPortId: outputContainerChange.outputPort.outputPortId,
      materialContainerId: outputContainerChange.container?.id ?? null,
      previousContainerFilled: outputContainerChange.previousContainerFilled,
      changeTime: dayjs
        .utc(outputContainerChange.effectiveTimestamp)
        .tz(facility.timeZoneId),
    },
  );

  const { data: process } = useProcess(form.values.processId ?? undefined);

  if (isLoadingError) {
    throw error;
  }

  if (patchMutation.isError) {
    return (
      <Alert
        color='red'
        title='Error Updating Output Container Change'
        withCloseButton
        onClose={() => patchMutation.reset()}
      >
        Your changes to the output container change may or may not have been
        saved. You can clear the error and try again.
      </Alert>
    );
  }

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        if (values.processId === null) {
          throw new Error();
        }

        const patch: OutputContainerChangePatchDTO = {
          ...(form.isDirty('changeTime') && {
            effectiveTimestamp: values.changeTime.utc().toISOString(),
          }),
          ...(form.isDirty('previousContainerFilled') && {
            previousContainerFilled: values.previousContainerFilled,
          }),
          ...(form.isDirty('materialContainerId') && {
            containerId: values.materialContainerId,
          }),
        };

        patchMutation.mutate(
          { outputContainerChangeId: modelId, patch },
          {
            onError() {
              onCancel();
              showNotification({
                title: 'Error Updating Output Container Change',
                message: `An error occurred updating the output container change`,
                color: 'red',
                icon: <IconX />,
              });
            },
            onSuccess() {
              onSuccess();
              showNotification({
                title: 'Output Container Change Updated',
                message:
                  'The output container change was successfully updated.',
                color: 'green',
                icon: <IconCheck />,
              });
            },
          },
        );
      })}
    >
      <Stack>
        <SelectedFacilityRequiredDateTimePicker
          required
          withAsterisk
          label='Change Time'
          maxDate={dayjs()}
          {...form.getInputProps('changeTime')}
        />

        <ProcessSelect
          disabled
          label='Process'
          emptyContent={
            <Alert title='No Processes' color='red'>
              Cannot create a output container change without a process.
            </Alert>
          }
          {...form.getInputProps('processId')}
        />

        <ProcessOutputPortSelect
          disabled
          label='Output Port'
          processOutputPorts={process?.outputs}
          {...form.getInputProps('outputPortId')}
        />

        <ContainerSelect
          label='New Output Container'
          facilityId={facility.id}
          timestamp={form.values.changeTime}
          hideEmpty={false}
          hideFull={false}
          {...form.getInputProps('materialContainerId')}
        />

        <Checkbox
          label='Previous Output Container Filled'
          size='md'
          checked={form.values.previousContainerFilled}
          onChange={(event) => {
            form.setFieldValue(
              'previousContainerFilled',
              event.currentTarget.checked,
            );
          }}
        />

        <Group position='right' mt='md'>
          <FormActionButton
            action='cancel'
            onClick={onCancel}
            loading={patchMutation.isLoading}
          >
            Cancel
          </FormActionButton>
          <FormActionButton
            type='submit'
            action='saveEdits'
            loading={patchMutation.isLoading}
          >
            Save Output Container Change
          </FormActionButton>
        </Group>
      </Stack>
    </form>
  );
}
