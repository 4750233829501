import { DrawerForm, DrawerFormDrawerProps } from '../Form/DrawerForm.tsx';
import { useCommodity, usePatchCommodity } from '../api/commodity.ts';
import { useSetFormInitialValuesFromQuery } from '../lib/useSetFormInitialValuesFromQuery.ts';
import { CommodityId, CommodityPatchDTO } from '../rest-client/index.ts';
import {
  CommodityFormFields,
  CommodityFormValues,
  useCommodityForm,
} from './CommodityForm.tsx';

export type EditCommodityDrawerFormProps = {
  commodityId: CommodityId;
  onSuccess?: (commodity: CommodityPatchDTO) => void;
} & DrawerFormDrawerProps;

export function EditCommodityDrawerForm(props: EditCommodityDrawerFormProps) {
  const { commodityId, onSuccess, onClose, ...drawerFormDrawerProps } = props;

  const { data: commodity, isLoadingError, error } = useCommodity(commodityId);
  const patchMutation = usePatchCommodity(commodityId);
  const form = useCommodityForm();

  useSetFormInitialValuesFromQuery(
    form,
    commodity && {
      name: commodity.name,
      description: commodity.description,
    },
  );

  if (isLoadingError) {
    throw error;
  }

  const formValuesToDto = ({ name }: CommodityFormValues) => {
    const commodityPatch: CommodityPatchDTO = {
      ...(form.isDirty('name') && { name }),
    };
    return commodityPatch;
  };

  return (
    <DrawerForm
      entityName='Commodity'
      form={form}
      drawerFormVariant='edit'
      mutation={patchMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      onClose={onClose}
      {...drawerFormDrawerProps}
    >
      <CommodityFormFields form={form} />
    </DrawerForm>
  );
}
